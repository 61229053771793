/* eslint-disable prettier/prettier */
import React, { useState, useEffect } from "react";
import { Modal, Form, Input } from "antd";
import { PartGroupModel, PartModel } from "servicesNew/api";
import type { Part } from "servicesNew/api/models/Part";
import {
  PartModelType,
  AtLeast,
} from "../../../productNew/advancedProductCreatorNew/types";
import { useParts } from "../hooks";
import { Col, Row } from "antd";
import { Button } from "antd";
import { AssociationModuleMaster } from "./associationModuleMaster";

const { TextArea } = Input;

interface ViewEditPartAssociationModal {
  open?: boolean;
  onCancel?: () => void;
  onSubmit?: (selectedPart: Required<PartModel>[]) => void;
  itemVersionData?: any;
  selectedPartGroupId?: number;
  partData?: PartModelType;
  itemsData?: any;
  activeTab?: string;
}

export const ViewEditPartAssociationModal: React.FC<ViewEditPartAssociationModal> = ({
  open = false,
  onCancel = () => {},
  onSubmit = () => {},
  itemVersionData,
  selectedPartGroupId,
  itemsData,
  activeTab = "1",
}) => {
  const [selectedParts, setSelectedParts] = useState<Record<string, boolean>>({});
  const [modifiedParts, setModifiedParts] = useState<{added: number[], removed: number[]}>({added: [], removed: []});
  const [hasChanges, setHasChanges] = useState(false);
  const [uiMessage, setUiMessage] = useState("");
  const [associationType, setAssociationType] = useState("");
  const [initialUiMessage, setInitialUiMessage] = useState("");
  const [initialAssociationType, setInitialAssociationType] = useState("");
  const [selectedID, setSelectedID] = useState<number | null>(null);
  const [initialSelectedParts, setInitialSelectedParts] = useState<{ id: number; name: string; childrens: any }[]>([]);
  const [form] = Form.useForm();
  const { createPartAssociationGroup, createPartAssociation, deletePartAssociation, deletePartAssociationGroup, patchPartAssociation } = useParts();

  const handleOnSelectPart = (partId: number, isSelected: boolean) => {
    if (!selectedID) return; // Don't proceed if no association is selected

    // If we're resetting, we want to clear the modified parts
    if (isSelected && modifiedParts.removed.includes(partId)) {
      setModifiedParts(prev => ({
        ...prev,
        removed: prev.removed.filter(id => id !== partId)
      }));
    } else if (!isSelected && modifiedParts.added.includes(partId)) {
      setModifiedParts(prev => ({
        ...prev,
        added: prev.added.filter(id => id !== partId)
      }));
    }

    setSelectedParts((prevSelected) => {
      const wasSelected = prevSelected[partId];
      const newSelected = {
        ...prevSelected,
        [partId]: isSelected,
      };

      // Update modified parts within this callback to ensure we have the latest state
      setModifiedParts(prev => {
        const newModified = { ...prev };
        if (!wasSelected && isSelected) {
          // Adding a part
          if (!newModified.added.includes(partId)) {
            newModified.added = [...newModified.added, partId];
          }
          newModified.removed = newModified.removed.filter(id => id !== partId);
        } else if (wasSelected && !isSelected) {
          // Removing a part
          if (!newModified.removed.includes(partId)) {
            newModified.removed = [...newModified.removed, partId];
          }
          newModified.added = newModified.added.filter(id => id !== partId);
        }
        return newModified;
      });

      // Set changes flag within this callback
      setHasChanges(true);

      return newSelected;
    });
  };

  const handleSubmitParts = async () => {
    console.log("handleSubmitParts in ViewEditPartAssociationModal called");
    try {
      // If UI message or association type has changed, make PATCH request
      if (uiMessage !== initialUiMessage || associationType !== initialAssociationType) {
        await patchPartAssociation(selectedID!, associationType, uiMessage);
      }

      // First handle all delete operations
      if (modifiedParts.removed.length > 0) {
        for (const partId of modifiedParts.removed) {
          try {
            await deletePartAssociation(partId, selectedID!);
          } catch (error) {
            console.error(`Failed to delete part association for partId=${partId}:`, error);
            throw error;
          }
        }
      }

      // Then handle all add operations - but only for truly new associations
      if (modifiedParts.added.length > 0) {
        // Filter out any parts that already have this association
        const newAssociations = modifiedParts.added.filter(partId => {
          // Check if this part already has the association in the data
          const hasExistingAssociation = itemsData?.childrens?.some((childOne: any) =>
            childOne.childrens?.some((childTwo: any) =>
              childTwo.childrens?.some((childThree: any) =>
                childThree.childrens?.some((childFour: any) =>
                  childFour.id === partId && 
                  childFour.part_association_groups?.some(
                    (group: any) => group.id === selectedID
                  )
                )
              )
            )
          );

          return !hasExistingAssociation;
        });

        const addPromises = newAssociations.map(partId => {
          const partData: AtLeast<PartModelType, "part_association_group_id"> = {
            id: partId,
            part_association_group_id: selectedID!,
            _uuid: '',
            name: '',
            is_active: true,
            created_at: new Date().toISOString(),
            updated_at: new Date().toISOString(),
            is_deleted: false,
            deleted_at: null,
            old_id: undefined,
            sku: null,
            display_sku: false,
            part_group_id: undefined,
            thumbnail: null,
            price_mod: 0,
            version_number: 0,
            is_published: false,
            base_price: 0,
            is_master: false,
            item: undefined,
            reference_file: [],
            images: [],
            status: '',
            item_version_id: undefined,
            internal_url: null,
            external_url: null,
            thumbnail_post_url: null,
            ui_message: null,
            type: null,
            list_order: null,
            visible: true,
            destination_part_id: undefined,
            origin_part_id: undefined,
            materials: null,
            custom_request_message: null
          };
          return createPartAssociation(partData);
        });

        await Promise.all(addPromises);
      }

      // Reset states
      setModifiedParts({added: [], removed: []});
      setHasChanges(false);
      
      if (onSubmit) {
        onSubmit([]);
      }
    } catch (error) {
      console.error('Error updating part associations:', error);
    }
  };

  const handleCancelParts = () => {
    onCancel();
    setSelectedParts({});
  };

  useEffect(() => {
    // Set initial values when a new association is selected
    if (selectedID && itemsData?.childrens) {
      // Reset initial selected parts
      setInitialSelectedParts([]);
      
      // Find the association group in the data
      for (const childOne of itemsData.childrens) {
        for (const childTwo of childOne.childrens) {
          for (const childThree of childTwo.childrens) {
            // Track parts for this group
            const groupParts: any[] = [];
            
            for (const childFour of childThree.childrens) {
              // Check for part associations
              const partAssociationGroup = childFour.part_association_groups?.find(
                (group: any) => group.id === selectedID
              );
              
              if (partAssociationGroup) {
                // Set initial UI message and type only on first match
                if (initialUiMessage === "" && initialAssociationType === "") {
                  setInitialUiMessage(partAssociationGroup.ui_message || "");
                  setInitialAssociationType(partAssociationGroup.type || "");
                  setUiMessage(partAssociationGroup.ui_message || "");
                  setAssociationType(partAssociationGroup.type || "");
                }
                
                // Add part to group parts
                groupParts.push({
                  id: childFour.id,
                  name: childFour.name,
                  partGroupId: childThree.id,
                  type: partAssociationGroup.type,
                  ui_message: partAssociationGroup.ui_message,
                });
              }
            }
            
            // If we found parts for this group, add them to initialSelectedParts
            if (groupParts.length > 0) {
              setInitialSelectedParts(prev => [
                ...prev,
                {
                  id: childThree.id,
                  name: childThree.name,
                  childrens: groupParts
                }
              ]);
            }
          }
        }
      }
    }
  }, [selectedID, itemsData]);

  console.log("ViewEditPartAssociationModal render:", {
    open,
    activeTab,
    hasChanges,
    selectedID
  });

  return (
    <Modal
      title="View/Edit Associations"
      open={open}
      onCancel={handleCancelParts}
      afterClose={() => {
        setSelectedParts({});
      }}
      className="view-edit-modal"
      
    >
      <div className="search-bar">
        <Row gutter={40}>
          <Col span={5}>
            <span className="search-heading">Search In</span>
            <TextArea className="search-textarea" autoSize />
          </Col>
          <Col span={5}>
            <span className="search-heading">Search Type</span>
            <TextArea className="search-textarea" autoSize />
          </Col>
          <Col span={8}>
            <span className="search-heading">Enter Text</span>
            <TextArea className="search-textarea" autoSize />
          </Col>
          <Col span={5} className="search-btn">
            <Button size="large">Search</Button>
          </Col>
        </Row>
      </div>
      <div className="main-content">
        <AssociationModuleMaster
          selectedID={selectedID}
          itemsData={itemsData}
          initialSelectedParts={initialSelectedParts}
          onPartSelect={handleOnSelectPart}
          activeTab={activeTab}
          onUiMessageChange={(message) => {
            setUiMessage(message);
            setHasChanges(message !== initialUiMessage || associationType !== initialAssociationType);
          }}
          onAssociationTypeChange={(type) => {
            setAssociationType(type);
            setHasChanges(uiMessage !== initialUiMessage || type !== initialAssociationType);
          }}
          onDeleteClick={async () => {
            // Extract all selected parts for this association
            const selectedPartsData = [];
            if (itemsData?.childrens) {
              for (const childOne of itemsData.childrens) {
                for (const childTwo of childOne.childrens) {
                  for (const childThree of childTwo.childrens) {
                    for (const childFour of childThree.childrens) {
                      const hasAssociation = childFour.part_association_groups?.some(
                        (group: any) => group.id === selectedID
                      );
                      if (hasAssociation) {
                        selectedPartsData.push({
                          id: childFour.id,
                          name: childFour.name
                        });
                      }
                    }
                  }
                }
              }
            }
            
            try {
              await deletePartAssociationGroup(selectedID!, selectedPartsData);
              setSelectedID(null);
              setSelectedParts({});
              setModifiedParts({added: [], removed: []});
              setHasChanges(false);
              // Close the modal
              onCancel();
            } catch (error) {
              console.error('Failed to delete association group:', error);
            }
          }}
          onAssociationSelect={(associationId, tabType) => {
            if (associationId === null || tabType !== "1") {
              // Handle tab change or non-part tab selection - clear selection
              setSelectedID(null);
              setSelectedParts({});
              setModifiedParts({added: [], removed: []});
              setHasChanges(false);
              return;
            }

            // Only set the ID if we find a valid part association
            const isPartAssociation = itemsData?.childrens?.some((childOne: any) =>
              childOne.childrens?.some((childTwo: any) =>
                childTwo.childrens?.some((childThree: any) =>
                  childThree.childrens?.some((childFour: any) =>
                    childFour.part_association_groups?.some(
                      (group: any) => group.id === associationId
                    )
                  )
                )
              )
            );

            if (isPartAssociation) {
              setSelectedID(associationId);
              // Reset states when selecting a new association
              setSelectedParts({});
              setModifiedParts({added: [], removed: []});
              setHasChanges(false);
            } else {
              console.error('Attempted to select non-part association:', associationId);
            }
          }}
        />
      </div>
    </Modal>
  );
};
