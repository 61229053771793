/* eslint-disable prettier/prettier */
import React, { useState, useEffect } from "react";
import { MinusOutlined, DownOutlined, PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import { Collapse, Input, Button } from "antd";
import { useParts } from "../hooks";
import { PartModelType, AtLeast } from "../types";

const { Panel } = Collapse;

interface PartAssociationsViewProps {
  selectedID: number | null;
  itemsData: any;
  selectedItemId: number | null;
  initialSelectedParts?: { id: number; name: string; childrens: any }[];
  onPartSelect?: (partId: number, isSelected: boolean) => void;
  onUiMessageChange?: (message: string) => void;
  onAssociationTypeChange?: (type: string) => void;
  onDeleteClick?: () => void;
  activeTab?: string;
  onSubmit?: (selectedPart: any[]) => void;
}

type ChildThreeData = {
  name: string;
  id: number;
  childrens: any[];
};

const PartAssociationsView: React.FC<PartAssociationsViewProps> = ({
  selectedID,
  itemsData,
  selectedItemId,
  initialSelectedParts,
  onPartSelect,
  onUiMessageChange,
  onAssociationTypeChange,
  onDeleteClick,
  onSubmit,
}) => {
  const { patchPartAssociation, createPartAssociation, deletePartAssociation } = useParts();
  const [partPanelOpen, setPartPanelOpen] = useState(false);
  const handleCollapseChange = () => {
    setPartPanelOpen(!partPanelOpen);
  };
  const [hoveredPanelIndex, setHoveredPanelIndex] = useState<number | null>(null);
  const [idEdit, setIDEdit] = useState<number | boolean>(false);
  const [selectedParts, setSelectedParts] = useState<{ id: number; name: string; childrens: any }[]>([]);
  const [isTypePanelOpen, setIsTypePanelOpen] = useState(false);
  const [allPartsData, setAllPartsData] = useState<ChildThreeData[]>([]);
  const [uiMessage, setUiMessage] = useState("");
  const [associationType, setAssociationType] = useState("");
  const [initialUiMessage, setInitialUiMessage] = useState("");
  const [initialAssociationType, setInitialAssociationType] = useState("");
  const [modifiedParts, setModifiedParts] = useState<{added: number[], removed: number[]}>({added: [], removed: []});
  const [hasChanges, setHasChanges] = useState(false);

  useEffect(() => {
    console.log("Initializing parts data with:", {
      selectedID,
      selectedItemId,
      itemsData,
      activeTab: "1", // Always parts tab
      initialSelectedParts
    });

    setSelectedParts([]);
    setAllPartsData([]);

    if (!selectedID || !itemsData?.childrens) {
      console.log("Missing required data, skipping initialization");
      return;
    }

    // Initialize selected parts from prop
    if (initialSelectedParts?.length) {
      setSelectedParts(initialSelectedParts);
      
      // Set initial UI message and type
      const message = initialSelectedParts[0]?.childrens[0]?.ui_message || "";
      const type = initialSelectedParts[0]?.childrens[0]?.type || "";
      
      setInitialUiMessage(message);
      setInitialAssociationType(type);
      setUiMessage(message);
      setAssociationType(type);
    }

    // Extract all parts data
    for (const childOne of itemsData.childrens) {
      if (childOne.id === selectedItemId) {
        for (const childTwo of childOne.childrens) {
          for (const childThree of childTwo.childrens) {
            // Add to allPartsData if not already selected
            const isPartSelected = initialSelectedParts?.some(
              group => group.id === childThree.id
            );

            if (!isPartSelected) {
              setAllPartsData(prevData => {
                if (!prevData.some(data => data.id === childThree.id)) {
                  console.log("Adding to allPartsData:", {
                    name: childThree.name,
                    id: childThree.id
                  });
                  return [
                    ...prevData,
                    {
                      name: childThree.name,
                      id: childThree.id,
                      childrens: childThree.childrens,
                    },
                  ];
                }
                return prevData;
              });
            }
          }
        }
      }
    }

    // Notify parent about initial selections
    if (onPartSelect && initialSelectedParts?.length) {
      initialSelectedParts.forEach(group => {
        group.childrens.forEach((child: any) => {
          console.log("Notifying parent of initial part selection:", {
            partId: child.id,
            isSelected: true
          });
          onPartSelect(child.id, true);
        });
      });
    }
  }, [selectedID, initialSelectedParts]);

  useEffect(() => {
    // Update UI message and association type from selected parts
    const message = selectedParts[0]?.childrens[0]?.ui_message || "";
    const type = selectedParts[0]?.childrens[0]?.type || "";
    
    setUiMessage(message);
    setAssociationType(type);
    
    // Notify parent components
    if (onUiMessageChange) {
      onUiMessageChange(message);
    }
    if (onAssociationTypeChange) {
      onAssociationTypeChange(type);
    }
  }, [selectedParts]);

  const handleTypeCollapseChange = () => {
    setIsTypePanelOpen(!isTypePanelOpen);
  };

  const handleAddClick = (
    child: { id: number; name: string },
    partGroupId: number,
    partGroupName: string,
    parentIndex: number
  ) => {
    setAllPartsData((prevData) =>
      prevData
        .map((item, index) => {
          if (index === parentIndex) {
            return {
              ...item,
              childrens: item.childrens.filter((c) => c.id !== child.id),
            };
          }
          return item;
        })
        .filter((item) => item.childrens.length > 0)
    );

    setSelectedParts((prevSelectedParts) => [
      ...prevSelectedParts,
      {
        id: partGroupId,
        name: partGroupName,
        childrens: [child],
      },
    ]);

    // Update modifiedParts state
    setModifiedParts(prev => ({
      ...prev,
      added: [...prev.added, child.id],
      removed: prev.removed.filter(id => id !== child.id)
    }));
    setHasChanges(true);

    // Notify parent component about part selection
    if (onPartSelect) {
      onPartSelect(child.id, true);
    }
  };

  const handleDiscardClick = (
    partGroupId: number,
    partGroupName: string,
    child: { id: number; name: string }
  ) => {
    console.log("handleDiscardClick called with:", {
      partId: child.id,
      partGroupId,
      partGroupName,
      selectedID,
      currentSelectedParts: selectedParts
    });

    // Update modifiedParts state
    setModifiedParts(prev => ({
      ...prev,
      removed: [...prev.removed, child.id],
      added: prev.added.filter(id => id !== child.id)
    }));
    setHasChanges(true);

    // First notify parent about removal
    if (onPartSelect) {
      console.log("Calling onPartSelect to remove part:", {
        partId: child.id,
        isSelected: false
      });
      onPartSelect(child.id, false);
    }

    // Then update local state
    setSelectedParts((prevSelectedParts) => {
      console.log("Updating selectedParts, current state:", {
        prevSelectedParts,
        removingPartId: child.id
      });

      const updatedParts = prevSelectedParts
        .map((item) => {
          if (item.id === partGroupId && item.name === partGroupName) {
            return {
              ...item,
              childrens: item.childrens.filter((c: any) => c.id !== child.id),
            };
          }
          return item;
        })
        .filter((item) => item.childrens.length > 0);

      console.log("New selectedParts state:", updatedParts);
      return updatedParts;
    });

    // Find the correct part group in allPartsData
    const partGroup = allPartsData.find(
      (item) => item.id === partGroupId && item.name === partGroupName
    );

    console.log("Found part group in allPartsData:", {
      partGroup,
      partExists: partGroup?.childrens.some((c) => c.id === child.id)
    });

    // Only add back to allPartsData if it doesn't already exist
    if (partGroup && !partGroup.childrens.some((c) => c.id === child.id)) {
      setAllPartsData((prevAllPartsData) => {
        const updatedData = prevAllPartsData.map((item) => {
          if (item.id === partGroupId && item.name === partGroupName) {
            return {
              ...item,
              childrens: [...item.childrens, child],
            };
          }
          return item;
        });
        console.log("Updated allPartsData:", updatedData);
        return updatedData;
      });
    }
  };

  const content = (
    <div className="part-tab">
      <div className="selected-parts-section">
        <div className="parts-data">
          <div className="data-section">
            <div className="data-header">Selected Parts</div>
            {selectedParts.length !== 0 &&
              selectedParts.map((item, index) => (
                <div className="part-items" key={index} style={{ 
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '8px',
                  marginBottom: '16px'
                }}>
                  <div className="part-group-name" style={{ 
                    fontWeight: 'bold',
                    color: '#666'
                  }}>{item.name}</div>
                  <div style={{ 
                    display: 'flex',
                    flexDirection: 'column',
                    paddingLeft: '16px',
                    gap: '4px'
                  }}>
                    {item.childrens &&
                      item.childrens.length > 0 &&
                      item.childrens.map(
                      (
                        child: { id: number; name: string },
                        childIndex: number
                      ) => (
                        <div
                          key={`${child.id}-${childIndex}`}
                          className="child-item-container"
                          style={{ display: 'flex', alignItems: 'center' }}
                        >
                          <div className="child-item" style={{ marginRight: '8px' }}>{child.name}</div>
                          <span
                            className="discard-btn"
                            onClick={() =>
                              handleDiscardClick(item.id, item.name, child)
                            }
                          >
                            <MinusOutlined className="discard-icon" />
                          </span>
                        </div>
                      )
                    )}
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
      <div className="all-parts-section">
        <div className="all-parts-data">
          <div className="all-parts-data-section">
            <div className="all-parts-data-header">All Parts</div>
            <div className="part-items">
              <div className="parts-selection">
                <div
                  onMouseEnter={() => {
                    setIDEdit(true);
                  }}
                  onMouseLeave={() => {
                    setIDEdit(false);
                  }}
                >
                  {allPartsData.map(
                    (
                      childThree: {
                        id: number;
                        name: string;
                        childrens: { id: number; name: string }[];
                      },
                      index: number
                    ) => (
                      <div
                        onMouseEnter={() => setHoveredPanelIndex(index)}
                        onMouseLeave={() => setHoveredPanelIndex(null)}
                        key={index}
                      >
                        <Collapse
                          onChange={handleCollapseChange}
                          expandIcon={({ isActive }) =>
                            idEdit === true &&
                            hoveredPanelIndex === index && (
                              <div
                                className={`parts-selection-rotate-btn${
                                  partPanelOpen ? "-active" : ""
                                }`}
                              >
                                <DownOutlined
                                  rotate={isActive ? 180 : 0}
                                  className={`parts-selection-rotate-icon${
                                    partPanelOpen ? "-active" : ""
                                  }`}
                                />
                              </div>
                            )
                          }
                          expandIconPosition="right"
                          className="parts-selection-dropdown"
                        >
                          <Panel
                            header={childThree.name}
                            key={index}
                            className={`parts-selection-panel-header ${
                              hoveredPanelIndex === index && partPanelOpen
                                ? "active"
                                : ""
                            } ${
                              hoveredPanelIndex === index
                                ? "parts-selection-panel-hover"
                                : ""
                            }`}
                          >
                            {childThree.childrens
                              .filter((child) => {
                                // Check if this part is in the selected parts list
                                const isSelected = selectedParts.some(
                                  (selectedPart) =>
                                    selectedPart.id === childThree.id &&
                                    selectedPart.childrens.some(
                                      (selectedChild: any) =>
                                        selectedChild.id === child.id
                                    )
                                );
                                console.log("Filtering part:", {
                                  partId: child.id,
                                  partName: child.name,
                                  isSelected,
                                  selectedParts,
                                });
                                return !isSelected;
                              })
                              .map(
                                (
                                  child: { id: number; name: string },
                                  i: number
                                ) => {
                                  console.log(
                                    "Rendering part in All Parts:",
                                    {
                                      partId: child.id,
                                      partName: child.name,
                                      partGroupId: childThree.id,
                                      partGroupName: childThree.name,
                                    }
                                  );
                                  return (
                                    <div
                                      key={i}
                                      className="parts-selection-items-name"
                                    >
                                      {child.name}
                                      <span
                                        className="add-btn"
                                        onClick={() =>
                                          handleAddClick(
                                            child,
                                            childThree.id,
                                            childThree.name,
                                            index
                                          )
                                        }
                                      >
                                        <PlusOutlined className="add-icon" />
                                      </span>
                                    </div>
                                  );
                                }
                              )}
                          </Panel>
                        </Collapse>
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className="part-materials-container">
      {content}
      <div className="message-type-section" style={{ display: 'flex', gap: '20px', alignItems: 'flex-start' }}>
          <div className="ui-message" style={{ flex: 1 }}>
            <div className="ui-message-header">UI Message</div>
            <Input
              className="ui-message-input"
              value={uiMessage}
              onChange={(e) => {
                setUiMessage(e.target.value);
                if (onUiMessageChange) {
                  onUiMessageChange(e.target.value);
                }
              }}
            />
          </div>
          <div className="type" style={{ flex: 1 }}>
            <div className="type-header">Association Type</div>
            <Collapse
              onChange={handleTypeCollapseChange}
              expandIcon={({ isActive }) => (
                <div
                  className={`type-rotate-btn${
                    isTypePanelOpen ? "-active" : ""
                  }`}
                >
                  <DownOutlined
                    rotate={isActive ? 180 : 0}
                    className={`type-rotate-icon${
                      isTypePanelOpen ? "-active" : ""
                    }`}
                  />
                </div>
              )}
              expandIconPosition="right"
              className="items-dropdown"
            >
              <Panel
                header={associationType || selectedParts[0]?.childrens[0]?.type}
                key="1"
                className={`type-panel-header ${
                  isTypePanelOpen ? "active" : ""
                }`}
              >
                <p 
                  className="type-items-name" 
                  onClick={() => {
                    setAssociationType("invalid");
                    if (onAssociationTypeChange) {
                      onAssociationTypeChange("invalid");
                    }
                  }}
                >
                  Invalid
                </p>
                <p 
                  className="type-items-name"
                  onClick={() => {
                    setAssociationType("dictate");
                    if (onAssociationTypeChange) {
                      onAssociationTypeChange("dictate");
                    }
                  }}
                >
                  Dictate
                </p>
              </Panel>
            </Collapse>
          </div>
        </div>
      <div className="footer-section">
        <div className="footer-section-btn">
          <Button 
            className="reset-btn" 
            onClick={() => {
              console.log("Resetting parts data:", {
                initialSelectedParts,
                selectedParts,
                uiMessage,
                associationType
              });
              
              // Reset selected parts to initial state
              if (initialSelectedParts?.length) {
                setSelectedParts(initialSelectedParts);
                
                // Reset UI message and association type
                const message = initialSelectedParts[0]?.childrens[0]?.ui_message || "";
                const type = initialSelectedParts[0]?.childrens[0]?.type || "";
                
                setUiMessage(message);
                setAssociationType(type);
                
                if (onUiMessageChange) {
                  onUiMessageChange(message);
                }
                if (onAssociationTypeChange) {
                  onAssociationTypeChange(type);
                }
                
                // Notify parent about part selections
                if (onPartSelect) {
                  // First deselect all current parts
                  selectedParts.forEach(group => {
                    group.childrens.forEach((child: any) => {
                      onPartSelect(child.id, false);
                    });
                  });
                  
                  // Then select all initial parts
                  initialSelectedParts.forEach(group => {
                    group.childrens.forEach((child: any) => {
                      onPartSelect(child.id, true);
                    });
                  });
                }
              }
            }}
          >
            Reset
          </Button>
          {selectedID && (
            <Button 
              danger
              icon={<DeleteOutlined />}
              onClick={() => {
                if (onDeleteClick) {
                  onDeleteClick();
                }
              }}
            >
              Delete
            </Button>
          )}
          <Button 
            className="save-btn" 
            type="primary"
            style={{ padding: '0 24px', height: '40px', fontSize: '16px' }}
            onClick={async () => {
              try {
                // If UI message or association type has changed, make PATCH request
                if (uiMessage !== initialUiMessage || associationType !== initialAssociationType) {
                  await patchPartAssociation(selectedID!, associationType, uiMessage);
                }

                // First handle all delete operations
                if (modifiedParts.removed.length > 0) {
                  for (const partId of modifiedParts.removed) {
                    try {
                      await deletePartAssociation(partId, selectedID!);
                    } catch (error) {
                      console.error(`Failed to delete part association for partId=${partId}:`, error);
                      throw error;
                    }
                  }
                }

                // Then handle all add operations - but only for truly new associations
                if (modifiedParts.added.length > 0) {
                  // Filter out any parts that already have this association
                  const newAssociations = modifiedParts.added.filter(partId => {
                    // Check if this part already has the association in the data
                    const hasExistingAssociation = itemsData?.childrens?.some((childOne: any) =>
                      childOne.childrens?.some((childTwo: any) =>
                        childTwo.childrens?.some((childThree: any) =>
                          childThree.childrens?.some((childFour: any) =>
                            childFour.id === partId && 
                            childFour.part_association_groups?.some(
                              (group: any) => group.id === selectedID
                            )
                          )
                        )
                      )
                    );

                    return !hasExistingAssociation;
                  });

                  const addPromises = newAssociations.map(partId => {
                    const partData: AtLeast<PartModelType, "part_association_group_id"> = {
                      id: partId,
                      part_association_group_id: selectedID!,
                      _uuid: '',
                      name: '',
                      is_active: true,
                      created_at: new Date().toISOString(),
                      updated_at: new Date().toISOString(),
                      is_deleted: false,
                      deleted_at: null,
                      old_id: undefined,
                      sku: null,
                      display_sku: false,
                      part_group_id: undefined,
                      thumbnail: null,
                      price_mod: 0,
                      version_number: 0,
                      is_published: false,
                      base_price: 0,
                      is_master: false,
                      item: undefined,
                      reference_file: [],
                      images: [],
                      status: '',
                      item_version_id: undefined,
                      internal_url: null,
                      external_url: null,
                      thumbnail_post_url: null,
                      ui_message: null,
                      type: null,
                      list_order: null,
                      visible: true,
                      destination_part_id: undefined,
                      origin_part_id: undefined,
                      materials: null,
                      custom_request_message: null
                    };
                    return createPartAssociation(partData);
                  });

                  await Promise.all(addPromises);
                }

                // Reset states
                setModifiedParts({added: [], removed: []});
                setHasChanges(false);
                
                if (onSubmit) {
                  onSubmit([]);
                }
              } catch (error) {
                console.error('Error updating part associations:', error);
              }
            }}
          >
            Save
          </Button>
          
        </div>
      </div>
    </div>
  );
};

export default PartAssociationsView;
