/* eslint-disable prettier/prettier */
import React, { useState, useEffect } from "react";
import { Modal, Form, Input } from "antd";
import { MaterialModel, MeshMaterialModel } from "servicesNew/api";
import type { Material } from "servicesNew/api/models/Material";
import {
  MeshMaterialModelType,
  AtLeast,
} from "../../../productNew/advancedProductCreatorNew/types";
import { useMeshMaterials } from "../hooks";
import { Col, Row } from "antd";
import { Button } from "antd";
import { AssociationModuleMaster } from "./associationModuleMaster";

const { TextArea } = Input;

interface ViewEditMaterialAssociationModal {
  open?: boolean;
  onCancel?: () => void;
  onSubmit?: (selectedMaterial: Required<MeshMaterialModel>[]) => void;
  itemVersionData?: any;
  selectedMaterialGroupId?: number;
  materialData?: MeshMaterialModelType;
  itemsData?: any;
  activeTab?: string;
}

export const ViewEditMaterialAssociationModal: React.FC<ViewEditMaterialAssociationModal> = ({
  open = false,
  onCancel = () => {},
  onSubmit = () => {},
  itemVersionData,
  selectedMaterialGroupId,
  itemsData,
  activeTab = "1",
}) => {
  const [selectedMaterials, setSelectedMaterials] = useState<Record<string, boolean>>({});
  const [modifiedMaterials, setModifiedMaterials] = useState<{added: number[], removed: number[]}>({added: [], removed: []});
  const [hasChanges, setHasChanges] = useState(false);
  const [uiMessage, setUiMessage] = useState("");
  const [associationType, setAssociationType] = useState("");
  const [initialUiMessage, setInitialUiMessage] = useState("");
  const [initialAssociationType, setInitialAssociationType] = useState("");
  const [selectedID, setSelectedID] = useState<number | null>(null);
  const [form] = Form.useForm();
  const { createMeshMaterialAssociationGroup, createMeshMaterialAssociation, deleteMeshMaterialAssociation, deleteMeshMaterialAssociationGroup, patchMeshMaterialAssociation } = useMeshMaterials();

  const handleOnSelectMaterial = (materialId: number, isSelected: boolean) => {
    if (!selectedID) return; // Don't proceed if no association is selected

    // If we're resetting, we want to clear the modified materials
    if (isSelected && modifiedMaterials.removed.includes(materialId)) {
      setModifiedMaterials(prev => ({
        ...prev,
        removed: prev.removed.filter(id => id !== materialId)
      }));
    } else if (!isSelected && modifiedMaterials.added.includes(materialId)) {
      setModifiedMaterials(prev => ({
        ...prev,
        added: prev.added.filter(id => id !== materialId)
      }));
    }

    setSelectedMaterials((prevSelected) => {
      const wasSelected = prevSelected[materialId];
      const newSelected = {
        ...prevSelected,
        [materialId]: isSelected,
      };

      // Update modified materials within this callback to ensure we have the latest state
      setModifiedMaterials(prev => {
        const newModified = { ...prev };
        if (!wasSelected && isSelected) {
          // Adding a material
          if (!newModified.added.includes(materialId)) {
            newModified.added = [...newModified.added, materialId];
          }
          newModified.removed = newModified.removed.filter(id => id !== materialId);
        } else if (wasSelected && !isSelected) {
          // Removing a material
          if (!newModified.removed.includes(materialId)) {
            newModified.removed = [...newModified.removed, materialId];
          }
          newModified.added = newModified.added.filter(id => id !== materialId);
        }
        return newModified;
      });

      // Set changes flag within this callback
      setHasChanges(true);

      return newSelected;
    });
  };

  const handleSubmitMaterials = async () => {
    console.log("handleSubmitMaterials in ViewEditMaterialAssociationModal called");
    try {
      // If UI message or association type has changed, make PATCH request
      if (uiMessage !== initialUiMessage || associationType !== initialAssociationType) {
        await patchMeshMaterialAssociation(selectedID!, associationType, uiMessage);
      }

      // First handle all delete operations
      if (modifiedMaterials.removed.length > 0) {
        for (const materialId of modifiedMaterials.removed) {
          try {
            await deleteMeshMaterialAssociation(materialId, selectedID!);
          } catch (error) {
            console.error(`Failed to delete material association for materialId=${materialId}:`, error);
            throw error;
          }
        }
      }

      // Then handle all add operations - but only for truly new associations
      if (modifiedMaterials.added.length > 0) {
        // Filter out any materials that already have this association
        const newAssociations = modifiedMaterials.added.filter(materialId => {
          // Check if this material already has the association in the data
          const hasExistingAssociation = itemsData?.childrens?.some((childOne: any) =>
            childOne.childrens?.some((childTwo: any) =>
              childTwo.childrens?.some((childThree: any) =>
                childThree.childrens?.some((childFour: any) =>
                  childFour.childrens?.some((childFive: any) =>
                    childFive.id === materialId && 
                    childFive.material_associations?.some(
                      (assoc: any) => assoc.id === selectedID
                    )
                  )
                )
              )
            )
          );

          return !hasExistingAssociation;
        });

        const addPromises = newAssociations.map(materialId => {
          const materialData: AtLeast<MeshMaterialModelType, "id" | "material_association_group_id"> = {
            id: materialId,
            material_association_group_id: selectedID!,
            type: associationType || "dictate",
            ui_message: uiMessage || ""
          };
          return createMeshMaterialAssociation(materialData);
        });

        await Promise.all(addPromises);
      }

      // Reset states
      setModifiedMaterials({added: [], removed: []});
      setHasChanges(false);
      
      if (onSubmit) {
        onSubmit([]);
      }
    } catch (error) {
      console.error('Error updating material associations:', error);
    }
  };

  const handleCancelMaterials = () => {
    onCancel();
    setSelectedMaterials({});
  };

  useEffect(() => {
    // Set initial values when a new association is selected
    if (selectedID && itemsData?.childrens) {
      // Find the association group in the data
      for (const childOne of itemsData.childrens) {
        for (const childTwo of childOne.childrens) {
          for (const childThree of childTwo.childrens) {
            for (const childFour of childThree.childrens) {
              if (childFour.childrens?.length > 0) {
                for (const childFive of childFour.childrens) {
                  const materialAssociation = childFive.material_associations?.find(
                    (assoc: any) => assoc.id === selectedID
                  );
                  if (materialAssociation) {
                    setInitialUiMessage(materialAssociation.ui_message || "");
                    setInitialAssociationType(materialAssociation.type || "");
                    setUiMessage(materialAssociation.ui_message || "");
                    setAssociationType(materialAssociation.type || "");
                    return;
                  }
                }
              }
            }
          }
        }
      }
    }
  }, [selectedID, itemsData]);

  console.log("ViewEditMaterialAssociationModal render:", {
    open,
    activeTab,
    hasChanges,
    selectedID
  });

  return (
    <Modal
      title="View/Edit Material Associations"
      open={open}
      onCancel={handleCancelMaterials}
      afterClose={() => {
        setSelectedMaterials({});
      }}
      className="view-edit-modal"
      footer={[
        <Button
          key="cancel"
          onClick={handleCancelMaterials}
          className="cancel-btn"
        >
          Cancel
        </Button>,
        <Button
          key="save"
          onClick={handleSubmitMaterials}
          className="upload-btn"
          //disabled={!hasChanges || !selectedID}
        >
          Save Material
        </Button>
      ]}
    >
      <div className="search-bar">
        <Row gutter={40}>
          <Col span={5}>
            <span className="search-heading">Search In</span>
            <TextArea className="search-textarea" autoSize />
          </Col>
          <Col span={5}>
            <span className="search-heading">Search Type</span>
            <TextArea className="search-textarea" autoSize />
          </Col>
          <Col span={8}>
            <span className="search-heading">Enter Text</span>
            <TextArea className="search-textarea" autoSize />
          </Col>
          <Col span={5} className="search-btn">
            <Button size="large">Search</Button>
          </Col>
        </Row>
      </div>
      <div className="main-content">
        <AssociationModuleMaster
          selectedID={selectedID}
          itemsData={itemsData}
          onMaterialSelect={handleOnSelectMaterial}
          activeTab={activeTab}
          onUiMessageChange={(message) => {
            setUiMessage(message);
            setHasChanges(message !== initialUiMessage || associationType !== initialAssociationType);
          }}
          onAssociationTypeChange={(type) => {
            setAssociationType(type);
            setHasChanges(uiMessage !== initialUiMessage || type !== initialAssociationType);
          }}
          onDeleteMaterialAssociationClick={async () => {
            // Extract all selected materials for this association
            const selectedMaterialsData = [];
            if (itemsData?.childrens) {
              for (const childOne of itemsData.childrens) {
                for (const childTwo of childOne.childrens) {
                  for (const childThree of childTwo.childrens) {
                    for (const childFour of childThree.childrens) {
                      if (childFour.childrens?.length > 0) {
                        for (const childFive of childFour.childrens) {
                          const hasAssociation = childFive.material_associations?.some(
                            (assoc: any) => assoc.id === selectedID
                          );
                          if (hasAssociation) {
                            selectedMaterialsData.push({
                              id: childFive.id,
                              name: childFive.name
                            });
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            
            try {
              await deleteMeshMaterialAssociationGroup(selectedID!, selectedMaterialsData);
              setSelectedID(null);
              setSelectedMaterials({});
              setModifiedMaterials({added: [], removed: []});
              setHasChanges(false);
              // Close the modal
              onCancel();
            } catch (error) {
              console.error('Failed to delete association group:', error);
            }
          }}
          onAssociationSelect={(associationId, tabType) => {
            if (associationId === null || tabType !== "2") {
              // Handle tab change or non-material tab selection - clear selection
              setSelectedID(null);
              setSelectedMaterials({});
              setModifiedMaterials({added: [], removed: []});
              setHasChanges(false);
              return;
            }

            // Only set the ID if we find a valid material association
            console.log('Checking if association exists:', { associationId, itemsData });
            const isMaterialAssociation = itemsData?.childrens?.some((childOne: any) =>
              childOne.childrens?.some((childTwo: any) =>
                childTwo.childrens?.some((childThree: any) =>
                  childThree.childrens?.some((childFour: any) =>
                    childFour.childrens?.some((childFive: any) =>
                      childFive.material_associations?.some(
                        (assoc: any) => assoc.id === associationId
                      )
                    )
                  )
                )
              )
            );

            console.log('Association check result:', { isMaterialAssociation });

            if (isMaterialAssociation) {
              setSelectedID(associationId);
              // Reset states when selecting a new association
              setSelectedMaterials({});
              setModifiedMaterials({added: [], removed: []});
              setHasChanges(false);
            } else {
              console.error('Attempted to select non-material association:', associationId);
            }
          }}
        />
      </div>
    </Modal>
  );
};
