/* eslint-disable prettier/prettier */
import React, { useState, useEffect } from "react";
import { Tabs } from "antd";
import type { TabsProps } from "antd";
import { MinusOutlined, DownOutlined, PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import { Collapse } from "antd";
import { Input } from "antd";
import { Button } from "antd";
import { useMeshMaterials } from "../hooks";
import { AtLeast, MeshMaterialModelType } from "../types";

const { Panel } = Collapse;
interface MaterialAssociationsViewProps {
  selectedID: number | null;
  itemsData: any;
  selectedItemId: number | null;
  onMaterialSelect?: (materialId: number, isSelected: boolean) => void;
  onUiMessageChange?: (message: string) => void;
  onAssociationTypeChange?: (type: string) => void;
  onDeleteMaterialAssociationClick?: () => void;
  activeTab?: string;
  onSubmit?: (materials: any[]) => void;
}
interface Material {
  id: number;
  name: string;
  materialGroupId?: number;
  materialGroupName?: string;
  partId?: number;
  partName?: string;
}

interface Part {
  id: number;
  name: string;
  childrens: Material[];
}

type ChildThreeData = {
  name: string;
  id: number;
  childrens: Part[];
};

const MaterialAssociationsView: React.FC<MaterialAssociationsViewProps> = ({
  selectedID,
  itemsData,
  selectedItemId,
  onMaterialSelect,
  onUiMessageChange,
  onAssociationTypeChange,
  onDeleteMaterialAssociationClick,
  onSubmit,
}) => {
  const { patchMeshMaterialAssociation, deleteMeshMaterialAssociation, deleteMeshMaterialAssociationGroup, createMeshMaterialAssociation } = useMeshMaterials();
  console.log("MaterialAssociationsView - selectedID prop:", selectedID);
  const [materialPanelOpen, setMaterialPanelOpen] = useState(false);
  const [expandedParts, setExpandedParts] = useState<{[key: string]: boolean}>({});
  const handleCollapseChange = () => {
    setMaterialPanelOpen(!materialPanelOpen);
  };
  const [hoveredPanelIndex, setHoveredPanelIndex] = useState<number | null>(
    null
  );
  const [idEdit, setIDEdit] = useState<number | boolean>(false);
  const [selectedMaterials, setSelectedMaterials] = useState<
    { id: number; name: string; childrens: any }[]
  >([]);
  const [isTypePanelOpen, setIsTypePanelOpen] = useState(false);
  const [allMaterialsData, setAllMaterialsData] = useState<ChildThreeData[]>([]);
  const [uiMessage, setUiMessage] = useState("");
  const [associationType, setAssociationType] = useState("");
  const [initialUiMessage, setInitialUiMessage] = useState("");
  const [initialAssociationType, setInitialAssociationType] = useState("");
  const [initialSelectedMaterials, setInitialSelectedMaterials] = useState<{ id: number; name: string; childrens: any }[]>([]);
  const [modifiedMaterials, setModifiedMaterials] = useState<{added: number[], removed: number[]}>({added: [], removed: []});
  const [hasChanges, setHasChanges] = useState(false);

  useEffect(() => {
    console.log("Handling state changes:", {
      selectedID,
      selectedItemId,
      itemsData,
      activeTab: "2" // Always materials tab
    });

      setSelectedMaterials([]);
      setAllMaterialsData([]);
      setInitialSelectedMaterials([]);

      if (!selectedID || !itemsData?.childrens) {
        console.log("Missing required data, skipping initialization");
        return;
      }

    // Extract material associations from the data

  const extractMaterialAssociations = (
    id: number | null,
    children: Array<{
      id: number;
      name: string;
      childrens: Array<{
        childrens: Array<{
          id: number;
          name: string;
          childrens: Array<{
            id: number;
            name: string;
            childrens: Array<{
              id: number;
              name: string;
              material_associations?: Array<{
                id: number;
                type: string;
                ui_message: string;
              }>;
            }>;
          }>;
        }>;
      }>;
    }>,
    selectedID: number | null
  ) => {
      for (const childOne of children) {
        console.log("Processing childOne:", {
          id: childOne.id,
          name: childOne.name,
          childrenCount: childOne.childrens?.length
        });
        if (childOne.id === id) {
          console.log("Found matching childOne:", {
            id: childOne.id,
            name: childOne.name,
            childrenCount: childOne.childrens?.length
          });

          for (const childTwo of childOne.childrens) {
            for (const childThree of childTwo.childrens) {
              // Add to allMaterialsData with parts level
              setAllMaterialsData((prevData) => {
                if (!prevData.some((data) => data.id === childThree.id)) {
                  const partGroups = [];
                  for (const childFour of childThree.childrens) {
                    if (childFour.childrens) {
                      const materials = childFour.childrens.map(childFive => ({
                        id: childFive.id,
                        name: childFive.name,
                        materialGroupId: childThree.id,
                        materialGroupName: childThree.name,
                        partId: childFour.id,
                        partName: childFour.name
                      }));

                      partGroups.push({
                        id: childFour.id,
                        name: childFour.name,
                        childrens: materials
                      });
                    }
                  }

                  console.log("Adding materials to allMaterialsData:", {
                    groupId: childThree.id,
                    groupName: childThree.name,
                    partsCount: partGroups.length
                  });

                  return [
                    ...prevData,
                    {
                      name: childThree.name,
                      id: childThree.id,
                      childrens: partGroups,
                    },
                  ];
                }
                return prevData;
              });

              for (const childFour of childThree.childrens) {
                if (!childFour.childrens) continue;

                for (const childFive of childFour.childrens) {
                  // Check for material associations
                  const materialAssociations = childFive.material_associations || [];
                  console.log("Checking material associations for:", {
                    materialId: childFive.id,
                    materialName: childFive.name,
                    associationsCount: materialAssociations.length,
                    associations: materialAssociations,
                    selectedID
                  });

                  const matchingAssociation = materialAssociations.find(
                    (assoc: { id: number; type: string; ui_message: string }) => {
                      const isMatch = assoc.id === selectedID;
                      console.log("Checking association match:", {
                        assocId: assoc.id,
                        selectedID,
                        isMatch
                      });
                      return isMatch;
                    }
                  );

                  if (matchingAssociation) {
                    console.log("Found associated material:", {
                      materialId: childFive.id,
                      materialName: childFive.name,
                      groupId: childThree.id,
                      groupName: childThree.name
                    });
                    setSelectedMaterials((prevState) => {
                      const existingGroup = prevState.find(
                        (entry) =>
                          entry.id === childThree.id &&
                          entry.name === childThree.name
                      );

                      if (existingGroup) {
                        console.log("Adding material to existing group:", {
                          groupId: childThree.id,
                          materialId: childFive.id
                        });

                        return prevState.map((entry) =>
                          entry.id === childThree.id &&
                          entry.name === childThree.name
                            ? {
                                ...entry,
                                childrens: [
                                  ...entry.childrens,
                                  {
                                    id: childFive.id,
                                    name: childFive.name,
                                    materialGroupId: childThree.id,
                                    partId: childFour.id,
                                    partName: childFour.name,
                                    type: matchingAssociation.type || "",
                                    ui_message: matchingAssociation.ui_message || "",
                                  },
                                ],
                              }
                            : entry
                        );
                      } else {
                        console.log("Creating new group with material:", {
                          groupId: childThree.id,
                          materialId: childFive.id
                        });

                        const newState = [
                          ...prevState,
                          {
                            id: childThree.id,
                            name: childThree.name,
                            childrens: [
                              {
                                    id: childFive.id,
                                    name: childFive.name,
                                    materialGroupId: childThree.id,
                                    partId: childFour.id,
                                    partName: childFour.name,
                                    type: matchingAssociation.type || "",
                                    ui_message: matchingAssociation.ui_message || "",
                              },
                            ],
                          },
                        ];

                        // Store initial state when first loading the association
                        if (prevState.length === 0) {
                          setInitialSelectedMaterials(newState);
                          setInitialUiMessage(matchingAssociation.ui_message || "");
                          setInitialAssociationType(matchingAssociation.type || "");
                        }

                        return newState;
                      }
                    });

                    // Also notify parent component about initial selection
                    if (onMaterialSelect) {
                      console.log("Notifying parent of initial material selection:", {
                        materialId: childFive.id,
                        isSelected: true
                      });
                      onMaterialSelect(childFive.id, true);
                    }
                  }
                }
              }
            }
          }
        }
      }
    };
    extractMaterialAssociations(selectedItemId, itemsData.childrens, selectedID);
  }, [selectedID, itemsData, selectedItemId]); // Include all required dependencies

  useEffect(() => {
    // Update UI message and association type
    setUiMessage(selectedMaterials[0]?.childrens[0]?.ui_message || "");
    setAssociationType(selectedMaterials[0]?.childrens[0]?.type || "");
  }, [selectedMaterials]);

  const handleTypeCollapseChange = () => {
    setIsTypePanelOpen(!isTypePanelOpen);
  };

  const handleOnSelectMaterial = (materialId: number, isSelected: boolean) => {
    if (!selectedID) return; // Don't proceed if no association is selected

    // If we're resetting, we want to clear the modified materials
    if (isSelected && modifiedMaterials.removed.includes(materialId)) {
      setModifiedMaterials(prev => ({
        ...prev,
        removed: prev.removed.filter(id => id !== materialId)
      }));
    } else if (!isSelected && modifiedMaterials.added.includes(materialId)) {
      setModifiedMaterials(prev => ({
        ...prev,
        added: prev.added.filter(id => id !== materialId)
      }));
    }

    setSelectedMaterials((prevSelected) => {
      // Find if the material is currently selected by checking all groups and their children
      let wasSelected = false;
      let materialGroupId: number | null = null;
      let materialGroupName: string | null = null;
      let materialName: string | null = null;
      let foundMaterial: Material | null = null;

      for (const group of prevSelected) {
        const material = group.childrens.find((child: any) => child.id === materialId);
        if (material) {
          wasSelected = true;
          materialGroupId = group.id;
          materialGroupName = group.name;
          materialName = material.name;
          foundMaterial = material;
          break;
        }
      }

      // Update modified materials within this callback to ensure we have the latest state
      setModifiedMaterials(prev => {
        const newModified = { ...prev };
        if (!wasSelected && isSelected) {
          // Adding a material
          if (!newModified.added.includes(materialId)) {
            newModified.added = [...newModified.added, materialId];
          }
          newModified.removed = newModified.removed.filter(id => id !== materialId);
        } else if (wasSelected && !isSelected) {
          // Removing a material
          if (!newModified.removed.includes(materialId)) {
            newModified.removed = [...newModified.removed, materialId];
          }
          newModified.added = newModified.added.filter(id => id !== materialId);
        }
        return newModified;
      });

      // Set changes flag within this callback
      setHasChanges(true);

      // Return updated selectedMaterials array
      if (isSelected && !wasSelected && materialGroupId && materialGroupName && materialName) {
        // Add the material to its group
        if (!foundMaterial) {
          console.error("Material data not found");
          return prevSelected;
        }
        
        const uniqueGroupId = Date.now(); // Generate unique ID for new group
        return [
          ...prevSelected,
          {
            id: uniqueGroupId,
            name: materialGroupName,
            childrens: [
              {
                id: materialId,
                name: materialName,
                materialGroupId: uniqueGroupId,
                partId: foundMaterial?.partId || null,
                partName: foundMaterial?.partName || "",
                type: "dictate", // Default type for new associations
                ui_message: "", // Default empty message for new associations
              }
            ]
          }
        ];
      } else if (!isSelected && wasSelected && materialGroupId) {
        // Remove the material from its group
        return prevSelected
          .map(group => {
            if (group.id === materialGroupId) {
              return {
                ...group,
                childrens: group.childrens.filter((child: any) => child.id !== materialId)
              };
            }
            return group;
          })
          .filter(group => group.childrens.length > 0); // Remove empty groups
      }

      return prevSelected;
    });
  };

  const handleAddClick = (
    child: { id: number; name: string; partId?: number; partName?: string },
    materialGroupId: number,
    materialGroupName: string,
    parentIndex: number
  ) => {
    if (!selectedID) {
      console.error("Cannot add material without selectedID");
      return;
    }
    console.log("Adding material:", {
      materialId: child.id,
      materialName: child.name,
      groupId: materialGroupId,
      groupName: materialGroupName
    });

    setAllMaterialsData((prevData) =>
      prevData
        .map((item, index) => {
          if (index === parentIndex) {
            return {
              ...item,
              childrens: item.childrens.filter((c) => c.id !== child.id),
            };
          }
          return item;
        })
        .filter((item) => item.childrens.length > 0)
    );

    const uniqueGroupId = Date.now(); // Generate unique ID once to share between parent and child
    setSelectedMaterials((prevSelectedMaterials) => [
      ...prevSelectedMaterials,
      {
        id: uniqueGroupId, // Use the unique ID for the parent group
        name: materialGroupName,
        childrens: [{
          ...child,
          materialGroupId: uniqueGroupId, // Use the same unique ID for the child
          materialGroupName: materialGroupName,
          type: "dictate", // Default type for new associations
          ui_message: "", // Default empty message for new associations
          material_associations: [{ id: selectedID, type: "dictate", ui_message: "" }]
        }],
      },
    ]);

    // Notify parent component about material selection
    if (onMaterialSelect) {
      onMaterialSelect(child.id, true);
    }
    
    setModifiedMaterials(prev => ({
      ...prev,
      added: [...prev.added, child.id]
    }));
    setHasChanges(true);
  };

  const handleDiscardClick = (
    materialGroupId: number,
    materialGroupName: string,
    child: { id: number; name: string }
  ) => {
    console.log("handleDiscardClick called with:", {
      materialId: child.id,
      materialGroupId,
      materialGroupName,
      selectedID,
      currentSelectedMaterials: selectedMaterials
    });

    // First notify parent about removal
    if (onMaterialSelect) {
      console.log("Calling onMaterialSelect to remove material:", {
        materialId: child.id,
        isSelected: false
      });
      onMaterialSelect(child.id, false);
    }

    setModifiedMaterials(prev => ({
      ...prev,
      removed: [...prev.removed, child.id]
    }));
    setHasChanges(true);

    // Then update local state
    setSelectedMaterials((prevSelectedMaterials) => {
      console.log("Updating selectedMaterials, current state:", {
        prevSelectedMaterials,
        removingMaterialId: child.id
      });

      const updatedMaterials = prevSelectedMaterials
        .map((item) => {
          if (item.id === materialGroupId && item.name === materialGroupName) {
            return {
              ...item,
              childrens: item.childrens.filter((c: any) => c.id !== child.id),
            };
          }
          return item;
        })
        .filter((item) => item.childrens.length > 0);

      console.log("New selectedMaterials state:", updatedMaterials);
      return updatedMaterials;
    });

    // Find the correct material group in allMaterialsData
    const materialGroup = allMaterialsData.find(
      (item) => item.id === materialGroupId && item.name === materialGroupName
    );

    console.log("Found material group in allMaterialsData:", {
      materialGroup,
      materialExists: materialGroup?.childrens.some((c) => c.id === child.id)
    });

    // Only add back to allMaterialsData if it doesn't already exist
    if (materialGroup && !materialGroup.childrens.some((part) => part.childrens.some(m => m.id === child.id))) {
      setAllMaterialsData((prevAllMaterialsData) => {
        const updatedData = prevAllMaterialsData.map((item) => {
          if (item.id === materialGroupId && item.name === materialGroupName) {
            // Find the first part to add this material to
            const firstPart = item.childrens[0];
            if (firstPart) {
              return {
                ...item,
                childrens: item.childrens.map((part, index) => 
                  index === 0 ? {
                    ...part,
                    childrens: [...part.childrens, {
                      id: child.id,
                      name: child.name,
                      materialGroupId: materialGroupId,
                      materialGroupName: materialGroupName,
                      partId: part.id,
                      partName: part.name
                    }]
                  } : part
                )
              };
            }
          }
          return item;
        });
        console.log("Updated allMaterialsData:", updatedData);
        return updatedData;
      });
    }
  };

  const content = (
    <div className="material-tab">
      <div className="selected-materials-section">
        <div className="materials-data">
          <div className="data-section">
            <div className="data-header">Selected Materials</div>
            {selectedMaterials.length !== 0 && (
              <div className="material-items">
                {selectedMaterials.map((materialGroup) => (
                  <div key={materialGroup.id} className="material-group">
                    <div className="material-group-header">{materialGroup.name}</div>
                    {materialGroup.childrens.reduce((acc: Array<{id: number; name: string; materials: any[]}>, material: any) => {
                      // Group materials by part
                      const partId = material.partId;
                      const partName = material.partName;
                      
                      const existingPart = acc.find(p => p.id === partId);
                      if (existingPart) {
                        existingPart.materials.push(material);
                      } else {
                        acc.push({
                          id: partId,
                          name: partName,
                          materials: [material]
                        });
                      }
                      return acc;
                    }, []).map((part: {id: number; name: string; materials: any[]}) => (
                      <div key={`part-section-${materialGroup.id}-${part.id}-${Date.now()}`} className="part-section">
                        <div style={{ marginLeft: "20px" }} className="part-header">{part.name}</div>
                        {part.materials.map((material: any, index: number) => (
                          <div
                            key={`selected-${materialGroup.id}-${part.id}-${material.id}-${index}`}
                            className="child-item-container"
                          >
                            <div style={{ marginLeft: "40px" }} className="child-item">{material.name}</div>
                            <span
                              className="discard-btn"
                              onClick={() =>
                                handleDiscardClick(material.materialGroupId, materialGroup.name, material)
                              }
                            >
                              <MinusOutlined className="discard-icon" />
                            </span>
                          </div>
                        ))}
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="all-materials-section">
        <div className="all-materials-data">
          <div className="all-materials-data-section">
            <div className="all-materials-data-header">All Materials</div>
            <div className="material-items">
              <div className="materials-selection">
                <div
                  onMouseEnter={() => {
                    setIDEdit(true);
                  }}
                  onMouseLeave={() => {
                    setIDEdit(false);
                  }}
                >
                  {allMaterialsData.map(
                    (childThree: ChildThreeData, index: number) => (
                      <div
                        onMouseEnter={() => setHoveredPanelIndex(index)}
                        onMouseLeave={() => setHoveredPanelIndex(null)}
                        key={index}
                      >
                        <Collapse
                          onChange={handleCollapseChange}
                          expandIcon={({ isActive }) =>
                            idEdit === true &&
                            hoveredPanelIndex === index && (
                              <div
                                className={`materials-selection-rotate-btn${
                                  materialPanelOpen ? "-active" : ""
                                }`}
                              >
                                <DownOutlined
                                  rotate={isActive ? 180 : 0}
                                  className={`materials-selection-rotate-icon${
                                    materialPanelOpen ? "-active" : ""
                                  }`}
                                />
                              </div>
                            )
                          }
                          expandIconPosition="right"
                          className="materials-selection-dropdown"
                        >
                        <Panel
                          header={childThree.name}
                          key={index}
                          className={`materials-selection-panel-header ${
                            hoveredPanelIndex === index && materialPanelOpen
                              ? "active"
                              : ""
                          } ${
                            hoveredPanelIndex === index
                              ? "materials-selection-panel-hover"
                              : ""
                          }`}
                        >
                          {childThree.childrens.map((part: Part, partIndex) => (
                            <div key={partIndex} className="part-section">
                              <div 
                                className="part-header materials-selection-panel-header" 
                                style={{ 
                                  cursor: 'pointer',
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'space-between',
                                  padding: '8px 16px',
                                  marginBottom: '4px',
                                  marginLeft: '20px',
                                  backgroundColor: '#f5f5f5',
                                  borderRadius: '4px'
                                }}
                                onClick={() => {
                                  const partKey = `${childThree.id}-${part.id}`;
                                  setExpandedParts(prev => ({
                                    ...prev,
                                    [partKey]: !prev[partKey]
                                  }));
                                }}
                              >
                                <span>{part.name}</span>
                                <DownOutlined
                                  rotate={expandedParts[`${childThree.id}-${part.id}`] ? 180 : 0}
                                  style={{
                                    fontSize: '12px',
                                    transition: 'all 0.3s'
                                  }}
                                />
                              </div>
                              {expandedParts[`${childThree.id}-${part.id}`] && part.childrens.filter((child: Material) => {
                                // Find the material in the original data structure to check its associations
                                let material;
                                for (const c1 of itemsData.childrens) {
                                  for (const c2 of c1.childrens) {
                                    for (const c3 of c2.childrens) {
                                      for (const c4 of c3.childrens) {
                                        for (const c5 of c4.childrens) {
                                          if (c5.id === child.id) {
                                            material = c5;
                                            break;
                                          }
                                        }
                                      }
                                    }
                                  }
                                }

                                // Check if this material has the current association
                                const materialAssociations = material?.material_associations || [];
                                const hasMaterialAssociation = materialAssociations.some(
                                  (assoc: any) => {
                                    const isMatch = assoc.id === selectedID;
                                    console.log("Checking association:", {
                                      assocId: assoc.id,
                                      selectedID,
                                      isMatch
                                    });
                                    return isMatch;
                                  }
                                );

                                console.log("Filtering material:", {
                                  materialId: child.id,
                                  materialName: child.name,
                                  groupId: childThree.id,
                                  hasMaterialAssociation,
                                  materialAssociations,
                                  selectedID,
                                  material
                                });

                                // If the material has the association, it should be in Selected Materials
                                // So we filter it out from All Materials
                                return !hasMaterialAssociation;
                              })
                              .map((child: { id: number; name: string }, i: number) => {
                                console.log(
                                  "Rendering material in All Materials:",
                                  {
                                    materialId: child.id,
                                    materialName: child.name,
                                    materialGroupId: childThree.id,
                                    materialGroupName: childThree.name,
                                    partId: part.id,
                                    partName: part.name,
                                  }
                                );
                                return (
                                  <div
                                    key={`all-material-${childThree.id}-${part.id}-${child.id}-${index}-${i}-${Math.random()}`}
                                    className="materials-selection-items-name"
                                    style={{ marginLeft: '40px' }}
                                  >
                                    {child.name}
                                    <span
                                      className="add-btn"
                                      onClick={() =>
                                        handleAddClick(
                                          {
                                            ...child,
                                            partId: part.id,
                                            partName: part.name
                                          },
                                          childThree.id,
                                          childThree.name,
                                          index
                                        )
                                      }
                                    >
                                      <PlusOutlined className="add-icon" />
                                    </span>
                                  </div>
                                );
                              })}
                            </div>
                          ))}
                          </Panel>
                        </Collapse>
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className="materials-container">
      {content}
      <div className="message-type-section" style={{ display: 'flex', gap: '20px', alignItems: 'flex-start' }}>
          <div className="ui-message" style={{ flex: 1 }}>
            <div className="ui-message-header">UI Message</div>
            <Input
              className="ui-message-input"
              value={uiMessage}
              onChange={(e) => {
                setUiMessage(e.target.value);
                if (onUiMessageChange) {
                  onUiMessageChange(e.target.value);
                }
              }}
            />
          </div>
          <div className="type" style={{ flex: 1 }}>
            <div className="type-header">Association Type</div>
            <Collapse
              onChange={handleTypeCollapseChange}
              expandIcon={({ isActive }) => (
                <div
                  className={`type-rotate-btn${
                    isTypePanelOpen ? "-active" : ""
                  }`}
                >
                  <DownOutlined
                    rotate={isActive ? 180 : 0}
                    className={`type-rotate-icon${
                      isTypePanelOpen ? "-active" : ""
                    }`}
                  />
                </div>
              )}
              expandIconPosition="right"
              className="items-dropdown"
            >
              <Panel
                header={associationType || selectedMaterials[0]?.childrens[0]?.type}
                key="1"
                className={`type-panel-header ${
                  isTypePanelOpen ? "active" : ""
                }`}
              >
                <p 
                  className="type-items-name" 
                  onClick={() => {
                    setAssociationType("invalid");
                    if (onAssociationTypeChange) {
                      onAssociationTypeChange("invalid");
                    }
                  }}
                >
                  Invalid
                </p>
                <p 
                  className="type-items-name"
                  onClick={() => {
                    setAssociationType("dictate");
                    if (onAssociationTypeChange) {
                      onAssociationTypeChange("dictate");
                    }
                  }}
                >
                  Dictate
                </p>
              </Panel>
            </Collapse>
          </div>
        </div>
      <div className="footer-section">
        <div className="footer-section-btn">
          <Button 
            className="reset-btn" 
            onClick={() => {
              setSelectedMaterials(initialSelectedMaterials);
              setUiMessage(initialSelectedMaterials[0]?.childrens[0]?.ui_message || "");
              setAssociationType(initialSelectedMaterials[0]?.childrens[0]?.type || "");
              if (onUiMessageChange) {
                onUiMessageChange(initialSelectedMaterials[0]?.childrens[0]?.ui_message || "");
              }
              if (onAssociationTypeChange) {
                onAssociationTypeChange(initialSelectedMaterials[0]?.childrens[0]?.type || "");
              }
              if (onMaterialSelect) {
                Object.entries(selectedMaterials).forEach(([materialId, isSelected]) => {
                  if (isSelected) {
                    onMaterialSelect(Number(materialId), false);
                  }
                });
                initialSelectedMaterials.forEach(group => {
                  group.childrens.forEach((child: any) => {
                    onMaterialSelect(child.id, true);
                  });
                });
              }
              setModifiedMaterials({added: [], removed: []});
              setHasChanges(false);
              if (onSubmit) {
                onSubmit([]);
              }
            }}
          >
            Reset
          </Button>
          {selectedID && (
            <Button 
              danger
              icon={<DeleteOutlined />}
              onClick={async () => {
                // Extract all selected materials for this association
                const selectedMaterialsData = [];
                if (itemsData?.childrens) {
                  for (const childOne of itemsData.childrens) {
                    for (const childTwo of childOne.childrens) {
                      for (const childThree of childTwo.childrens) {
                        for (const childFour of childThree.childrens) {
                          if (childFour.childrens?.length > 0) {
                            for (const childFive of childFour.childrens) {
                              const hasAssociation = childFive.material_associations?.some(
                                (assoc: any) => assoc.id === selectedID
                              );
                              if (hasAssociation) {
                                selectedMaterialsData.push({
                                  id: childFive.id,
                                  name: childFive.name
                                });
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
                
                try {
                  await deleteMeshMaterialAssociationGroup(selectedID!, selectedMaterialsData);
                  setSelectedMaterials([]);
                  setModifiedMaterials({added: [], removed: []});
                  setHasChanges(false);
                } catch (error) {
                  console.error('Failed to delete association group:', error);
                }
              }}
            >
              Delete
            </Button>
          )}
          <Button 
            className="save-btn" 
            type="primary"
            style={{ padding: '0 24px', height: '40px', fontSize: '16px' }}
            onClick={async () => {
              try {
                // If UI message or association type has changed, make PATCH request
                if (uiMessage !== initialUiMessage || associationType !== initialAssociationType) {
                  await patchMeshMaterialAssociation(selectedID!, associationType, uiMessage);
                }

                // First handle all delete operations
                if (modifiedMaterials.removed.length > 0) {
                  for (const materialId of modifiedMaterials.removed) {
                    try {
                      await deleteMeshMaterialAssociation(materialId, selectedID!);
                    } catch (error) {
                      console.error(`Failed to delete material association for materialId=${materialId}:`, error);
                      throw error;
                    }
                  }
                }

                // Then handle all add operations - but only for truly new associations
                if (modifiedMaterials.added.length > 0) {
                  // Filter out any materials that already have this association
                  const newAssociations = modifiedMaterials.added.filter(materialId => {
                    // Check if this material already has the association in the data
                    const hasExistingAssociation = itemsData?.childrens?.some((childOne: any) =>
                      childOne.childrens?.some((childTwo: any) =>
                        childTwo.childrens?.some((childThree: any) =>
                          childThree.childrens?.some((childFour: any) =>
                            childFour.childrens?.some((childFive: any) =>
                              childFive.id === materialId && 
                              childFive.material_associations?.some(
                                (assoc: any) => assoc.id === selectedID
                              )
                            )
                          )
                        )
                      )
                    );

                    return !hasExistingAssociation;
                  });

                  const addPromises = newAssociations.map(materialId => {
                    const materialData: AtLeast<MeshMaterialModelType, "id" | "material_association_group_id"> = {
                      id: materialId,
                      material_association_group_id: selectedID!,
                      type: associationType || "dictate",
                      ui_message: uiMessage || "",
                      _uuid: `material-${materialId}`
                    };
                    return createMeshMaterialAssociation(materialData);
                  });

                  await Promise.all(addPromises);
                }

                // Reset states
                setModifiedMaterials({added: [], removed: []});
                setHasChanges(false);
                
                if (onSubmit) {
                  onSubmit([]);
                }
              } catch (error) {
                console.error('Error updating material associations:', error);
              }
            }}
          >
            Save
          </Button>
          
        </div>
      </div>
    </div>
  );
};

export default MaterialAssociationsView;
