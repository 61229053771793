import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Card, Layout, Modal, Skeleton } from "antd";
import {
  CollectionContextProvider,
  useCollectionContext,
} from "./contexts/CollectionContext";
import {
  useItemVersions,
  usePartGroups,
  useParts,
  useMeshMaterials,
  useItem,
} from "./hooks";
import { fetchAll } from "./utils/api";
import { CollectionExplorer } from "./components/collectionExplorer";
import {
  AddCollectionView,
  CollectionView,
  ItemView,
  ItemVersionView,
  PartGroupView,
  PartView,
  MeshMaterialView,
  //AssociationView,
} from "./views";
import { CollectionBreadcrumb } from "./components/collectionBreadcrumb";
import { FormActionFooter } from "./components/formActionFooter";
import { ActionsMenu } from "./components/actionsMenu";
import { AddModal } from "./components/addModal";
import { AddMeshMaterialsModal } from "./components/addMeshMaterialsModal";
import { CreateMeshMaterialAssociationModal } from "./components/createMeshMaterialAssociationModal";
import { CreatePartAssociationModal } from "./components/createPartAssociationModal";
import { ViewEditPartAssociationModal } from "./components/viewEditPartAssociationModal";
import { ViewEditMaterialAssociationModal } from "./components/viewEditMaterialAssociationModal";
import { PartMaterialAssociation } from "./components/partMaterialAssociation";
import FullAreaSpinner from "components/Loaders/FullAreaSpinner";
import { ModelType, ModelTypeLabel } from "./types";
import { getMeshMaterialModel, getPartModel } from "./utils/dataModel";
import { UIContextProvider, useUIContext } from "./contexts/UIContext";
import { MaterialModel, MeshMaterialModel } from "servicesNew/api";
import { PartModel } from "servicesNew/api";

// import { CollectionView } from "./views/collectionView";
const { Content, Sider } = Layout;

interface AdvancedProductCreatorCoreProps {
  companyId: number;
  orderId: number;
  itemCollectionId?: number;
}

const AdvancedProductCreatorCore = ({
  companyId,
  orderId,
  itemCollectionId,
}: AdvancedProductCreatorCoreProps) => {
  const { isDirty, setIsDirty } = useUIContext();
  const mode = useMemo(
    () => (itemCollectionId ? "edit" : "create"),
    [itemCollectionId]
  );
  const { addItem, removeItem, cloneItem } = useItem();
  const { /*addItemVersion,*/ removeItemVersion } = useItemVersions();
  const { addPartGroup, clonePartGroup, removePartGroup } = usePartGroups();
  const {
    addPart,
    clonePart,
    removePart,
    createPartAssociationGroup,
    createPartAssociation,
    //deletePartAssociation,
  } = useParts();
  const {
    addMeshMaterials,
    removeMeshMaterial,
    createMeshMaterialAssociationGroup,
    createMeshMaterialAssociation,
    //deleteMeshMaterialAssociation,
  } = useMeshMaterials();
  //const [showAssociationView, setShowAssociationView] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [modal, modalContextHolder] = Modal.useModal();
  const [selectedPartsData, setSelectedPartsData] = useState([]);
  const [addModalState, setAddModalState] = useState<{
    open: boolean;
    title: string;
    type: ModelType | null;
  }>({ open: false, title: "Add", type: null });
  const [createModalState, setCreateModalState] = useState<{
    open: boolean;
    title: string;
    type: ModelType | null;
  }>({ open: false, title: "Create", type: null });
  const [openMeshMaterialsModal, setOpenMeshMaterialsModal] =
    useState<boolean>(false);
  const [
    openMeshMaterialAssociationModal,
    setOpenMeshMaterialAssociationModal,
  ] = useState<boolean>(false);
  const [
    openPartMeshMaterialAssociationModal,
    setOpenPartMeshMaterialAssociationModal,
  ] = useState<boolean>(false);
  const [openPartAssociationModal, setOpenPartAssociationModal] =
    useState<boolean>(false);
  const [viewEditAssociation, setViewEditAssociation] =
    useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<"1" | "2">("1");
  const {
    collection,
    setCollection,
    selectedKey,
    setSelectedKey,
    selectedItem,
  } = useCollectionContext();

  /**************** Actions ******************/
  const actionOnAdd = useCallback(
    (name: string, type: ModelType) => {
      const { collection, item, itemVersion, partGroup } =
        selectedItem?.data || {};

      switch (type) {
        case "item":
          if (collection) addItem({ collection, name, order_id: orderId });
          break;
        //case "itemVersion":
        //  if (item) addItemVersion(item);
        //  break;

        case "partGroup":
          if (item) addPartGroup({ name, item });
          // case "partGroup":
          //   if (itemVersion) addPartGroup({ name, itemVersion });
          break;
        case "part":
          if (partGroup) addPart({ name, partGroup });
          break;
        default:
          break;
      }
    },
    [addItem, /*addItemVersion,*/ addPart, addPartGroup, selectedItem?.data]
  );
  // console.log("selectedItem", selectedItem);
  const actionOnDelete = useCallback(
    (type: ModelType) => {
      if (selectedItem) {
        const {
          data: { item, itemVersion, partGroup, part, meshMaterial },
        } = selectedItem;
        // console.log("meshMaterial", meshMaterial);

        switch (type) {
          case "item":
            if (item) removeItem({ item });
            break;
          case "itemVersion":
            if (itemVersion) removeItemVersion({ itemVersion });
            break;
          case "partGroup":
            if (partGroup) removePartGroup({ partGroup });
            break;
          case "part":
            if (part) removePart({ part });
            break;
          case "meshMaterial":
            if (meshMaterial) removeMeshMaterial(meshMaterial, part);
            break;
          default:
            break;
        }
      }
    },
    [
      removeItem,
      removeItemVersion,
      removeMeshMaterial,
      removePart,
      removePartGroup,
      selectedItem,
    ]
  );
  const actionOnClone = useCallback(
    async (type: ModelType) => {
      if (selectedItem) {
        const {
          data: { partGroup, part, item, collection, itemVersion },
        } = selectedItem;
        switch (type) {
          case "part":
            if (partGroup && part) clonePart({ part, partGroup, selectedItem });
            break;
          case "partGroup":
            if (partGroup) clonePartGroup({ partGroup, selectedItem });
            break;
          case "item":
            if (collection && item)
              cloneItem({ item, collection, partGroup, selectedItem });
            break;
          default:
            break;
        }
      }
    },
    [clonePart, clonePartGroup, selectedItem]
  );
  const onAddMeshMaterial = useCallback(
    (materials: Required<MaterialModel>[]) => {
      const price_mod = 0;
      //const materialPriceModOverrideDefault = 0;
      if (selectedItem?.data.part) {
        const destinationPart = selectedItem.data.part;
        //const originPart =  logic to determine originPart ;
        const meshMaterials = materials.map((material) => {
          return {
            ...getMeshMaterialModel(
              material,
              price_mod
              //materialPriceModOverrideDefault
            ), // Assuming getMeshMaterialModel transforms MaterialModel to MeshMaterialModelType
            part: destinationPart.id, // Add destinationPart ID to each meshMaterial
            // Include other properties as needed from material to meshMaterial
          };
        });
        addMeshMaterials({
          //originPart,
          destinationPart,
          meshMaterials,
          selectedItem,
        });
      }
    },
    [addMeshMaterials, selectedItem]
  );
  const onCreatePartAssociation = useCallback(
    (parts: Required<PartModel>[]) => {
      // Renamed to avoid naming conflict
      if (selectedItem?.data.part) {
        // Assuming getPartAssociationModel is defined and transforms PartModel to the required type
        const transformedParts = parts.map((part) => {
          return { ...getPartModel(part) };
        });
        createPartAssociation({
          part_association_group_id: transformedParts,
        });
      }
    },
    [createPartAssociation, selectedItem]
  );
  const onCreatePartMaterialAssociation = useCallback(
    (parts: Required<PartModel>[]) => {
      // Renamed to avoid naming conflict
      if (selectedItem?.data.part) {
        // Assuming getPartAssociationModel is defined and transforms PartModel to the required type
        const transformedParts = parts.map((part) => {
          return { ...getPartModel(part) };
        });
        createPartAssociation({
          part_association_group_id: transformedParts,
        });
      }
    },
    [createPartAssociation, selectedItem]
  );
  const onCreateMeshMaterialAssociation = useCallback(
    (meshMaterials: Required<MeshMaterialModel>[]) => {
      // Renamed to avoid naming conflict
      const price_mod = 0;
      //const materialPriceModOverrideDefault = 0;
      if (selectedItem?.data.meshMaterial) {
        // Assuming getMeshMaterialAssociationModel is defined and transforms MeshMaterialtModel to the required type
        const transformedMeshMaterials = meshMaterials.map((meshMaterial) => {
          return {
            ...getMeshMaterialModel(
              meshMaterial,
              price_mod
              //materialPriceModOverrideDefault
            ),
          };
        });
        createMeshMaterialAssociation({
          material_association_group_id: transformedMeshMaterials,
        });
      }
    },
    [createMeshMaterialAssociation, selectedItem]
  );

  /*******************************************/

  /**************** Handlers *****************/
  const handleOnAdd = useCallback(
    (type: ModelType) => {
      if (type !== "meshMaterial") {
        if (type !== "itemVersion") {
          setAddModalState({
            open: true,
            title: `Add ${ModelTypeLabel[type]}`,
            type: type,
          });
        } else {
          actionOnAdd("", type);
        }
      } else {
        setOpenMeshMaterialsModal(true);
      }
    },
    [actionOnAdd]
  );

  const handleOnClone = useCallback(
    (type: ModelType) => {
      modal.confirm({
        title: `Do you want to clone this ${ModelTypeLabel[type]}`,
        icon: <ExclamationCircleOutlined />,
        okText: "Clone",
        cancelText: "Cancel",
        maskClosable: true,
        onOk: () => {
          actionOnClone(type);
        },
      });
    },
    [actionOnClone, modal]
  );
  const handleOnDelete = useCallback(
    (type: ModelType) => {
      modal.confirm({
        title: `Do you want to delete this ${ModelTypeLabel[type]}`,
        icon: <ExclamationCircleOutlined />,
        okText: "Delete",
        cancelText: "Cancel",
        okButtonProps: { danger: true },
        maskClosable: true,
        onOk: () => {
          actionOnDelete(type);
        },
      });
    },
    [actionOnDelete, modal]
  );
  // const handleOnCreate = useCallback(
  //   (type: ModelType) => {
  //     console.log("partMaterial-type", type);
  //     if (type !== "meshMaterial") {
  //       if (type !== "part") {
  //         setCreateModalState({
  //           open: true,
  //           title: `Create ${ModelTypeLabel[type]}`,
  //           type: type,
  //         });
  //       } else {
  //         setOpenPartAssociationModal(true);
  //       }
  //     } else {
  //       setOpenMeshMaterialAssociationModal(true);
  //     }
  //   },
  //   [
  //     setOpenPartAssociationModal,
  //     setOpenMeshMaterialAssociationModal,
  //     setOpenPartMeshMaterialAssociationModal,
  //   ]
  // );
  const handleOnCreate = useCallback(
    (type: ModelType) => {
      if (type === "partMaterial") {
        // Handle specific logic for "partMaterial"
        setOpenPartMeshMaterialAssociationModal(true);
      } else if (type !== "meshMaterial") {
        if (type !== "part") {
          setCreateModalState({
            open: true,
            title: `Create ${ModelTypeLabel[type]}`,
            type: type,
          });
        } else {
          setOpenPartAssociationModal(true);
        }
      } else {
        setOpenMeshMaterialAssociationModal(true);
      }
    },
    [setOpenPartMeshMaterialAssociationModal]
  );
  const handleOnDiscardChanges = useCallback(() => {
    return new Promise((resolve) => {
      modal.confirm({
        title: `Do you want to discard changes?`,
        icon: <ExclamationCircleOutlined />,
        okText: "Discard",
        cancelText: "Cancel",
        okButtonProps: { danger: true },
        maskClosable: true,
        onOk: () => {
          setIsDirty(false);
          resolve(true);
        },
        onCancel: () => {
          resolve(false);
        },
      });
    });
  }, [modal, setIsDirty]);

  const handleOnSelect = useCallback(
    async (uuid: string) => {
      if (isDirty) {
        const discardChanges = await handleOnDiscardChanges();
        if (discardChanges) setSelectedKey(uuid);
      } else {
        if (uuid) setSelectedKey(uuid);
      }
    },
    [handleOnDiscardChanges, isDirty, setSelectedKey]
  );

  const handleAssociationSelect = useCallback(
    (associationId: number | null, tabType: "1" | "2") => {
      console.log("handleAssociationSelect called with:", {
        associationId,
        tabType,
        currentTab: activeTab,
        viewEditAssociation,
      });
      if (associationId) {
        setViewEditAssociation(true);
        setActiveTab(tabType);
        console.log("Setting new tab and association state:", {
          newTab: tabType,
          viewEditAssociation: true,
        });
      } else {
        setViewEditAssociation(false);
      }
    },
    [activeTab, viewEditAssociation]
  );
  /*******************************************/

  useEffect(() => {
    setIsDirty(false);
  }, [setIsDirty, selectedKey]);

  // Track tab changes
  useEffect(() => {
    console.log("Tab or association state changed:", {
      activeTab,
      viewEditAssociation,
      shouldShowPartModal: viewEditAssociation && activeTab === "1",
      shouldShowMaterialModal: viewEditAssociation && activeTab === "2",
    });
  }, [activeTab, viewEditAssociation]);

  useEffect(() => {
    if (itemCollectionId) {
      setLoading(true);
      fetchAll(itemCollectionId)
        .then(({ collection: _collection }) => {
          setCollection(_collection);
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    }
  }, [itemCollectionId, setCollection]);

  useEffect(() => {
    if (openMeshMaterialAssociationModal) {
      console.log(
        "Opening CreateMeshMaterialAssociationModal with selectedItem:",
        selectedItem
      );
      // If you want to inspect the entire `selectedItem` object structure, log it directly
      // For a more detailed inspection of specific properties, log those properties individually, e.g.,
      console.log("ItemVersion ID:", selectedItem?.id);
      console.log("ItemVersion Data:", selectedItem?.data);
      // Add more logs as needed to inspect other relevant parts of `selectedItem`
    }
  }, [openMeshMaterialAssociationModal, selectedItem]);

  useEffect(() => {
    if (openPartAssociationModal) {
      console.log(
        "Opening CreateMeshMaterialPartAssociationModal with selectedItem:",
        selectedItem
      );
      // If you want to inspect the entire `selectedItem` object structure, log it directly
      // For a more detailed inspection of specific properties, log those properties individually, e.g.,
      console.log("ItemVersion ID:", selectedItem?.id);
      console.log("ItemVersion Data:", selectedItem?.data);
      // Add more logs as needed to inspect other relevant parts of `selectedItem`
    }
  }, [openPartAssociationModal, selectedItem]);

  return (
    <Layout className="advanced-product-creator-wrapper">
      <Sider width={350}>
        {loading ? (
          <div style={{ padding: "20px" }}>
            <Skeleton
              active
              loading={loading}
              paragraph={{ rows: 3, width: "100%" }}
            />
          </div>
        ) : (
          <CollectionExplorer
            collection={collection}
            selectedKey={selectedKey}
            onSelect={handleOnSelect}
            viewEditAssociation={viewEditAssociation}
            setViewEditAssociation={setViewEditAssociation}
            activeTab={activeTab}
            onTabChange={setActiveTab}
            onAssociationSelect={handleAssociationSelect}
            //disableCreateAssociation={mode === "create"}
            //onCreateAssociation={() => {
            //handleOnCreate("association");
            //}}
          />
        )}
      </Sider>
      <Content>
        {mode === "create" ? (
          <Card
            className="content-wrapper-card"
            size="small"
            title={<span>Create New Collection</span>}
          >
            <AddCollectionView companyId={companyId} orderId={orderId} />
          </Card>
        ) : (
          <>
            {loading ? (
              <FullAreaSpinner />
            ) : (
              <>
                {selectedItem && (
                  <CollectionBreadcrumb
                    selectedItem={selectedItem}
                    onSelect={handleOnSelect}
                  />
                )}
                {selectedItem && (
                  <Card
                    className="content-wrapper-card"
                    size="small"
                    title={
                      <span>
                        <b>{ModelTypeLabel[selectedItem.type]}:</b>{" "}
                        <i>{selectedItem.name}</i>
                      </span>
                    }
                  >
                    <ActionsMenu
                      onAdd={handleOnAdd}
                      onClone={handleOnClone}
                      onDelete={handleOnDelete}
                      onCreate={handleOnCreate}
                      selectedItem={selectedItem}
                    />
                    {selectedItem.type === "collection" ? (
                      <CollectionView
                        key={selectedItem._uuid}
                        selectedItem={selectedItem}
                      >
                        <FormActionFooter />
                      </CollectionView>
                    ) : selectedItem.type === "item" ? (
                      <ItemView
                        key={selectedItem._uuid}
                        selectedItem={selectedItem}
                      >
                        <FormActionFooter />
                      </ItemView>
                    ) : selectedItem.type === "itemVersion" ? (
                      <ItemVersionView
                        key={selectedItem._uuid}
                        selectedItem={selectedItem}
                      >
                        <FormActionFooter />
                      </ItemVersionView>
                    ) : selectedItem.type === "partGroup" ? (
                      <PartGroupView
                        key={selectedItem._uuid}
                        selectedItem={selectedItem}
                      >
                        <FormActionFooter />
                      </PartGroupView>
                    ) : selectedItem.type === "part" ? (
                      <PartView
                        key={selectedItem._uuid}
                        selectedItem={selectedItem}
                      >
                        <FormActionFooter />
                      </PartView>
                    ) : selectedItem.type === "meshMaterial" ? (
                      <MeshMaterialView
                        key={selectedItem._uuid}
                        selectedItem={selectedItem}
                      >
                        <FormActionFooter />
                      </MeshMaterialView>
                    ) : null}
                  </Card>
                )}
              </>
            )}
          </>
        )}
      </Content>
      {addModalState.open && (
        <AddModal
          title={addModalState.title}
          open={addModalState.open}
          onSubmit={(name) => {
            if (addModalState.type) actionOnAdd(name, addModalState.type);
            setAddModalState({ open: false, title: "", type: null });
          }}
          onCancel={() => {
            setAddModalState({ open: false, title: "", type: null });
          }}
        />
      )}
      <AddMeshMaterialsModal
        open={openMeshMaterialsModal}
        onCancel={() => {
          setOpenMeshMaterialsModal(false);
        }}
        onSubmit={(meshMaterials) => {
          onAddMeshMaterial(meshMaterials);
          setOpenMeshMaterialsModal(false);
        }}
      />
      <CreatePartAssociationModal
        open={openPartAssociationModal}
        onCancel={() => {
          setOpenPartAssociationModal(false);
        }}
        onSubmit={(parts) => {
          onCreatePartAssociation(parts);
          setOpenPartAssociationModal(false);
        }}
        itemVersionData={selectedItem}
      />

      {/* Debug output */}
      {(() => {
        console.log("Modal states:", {
          viewEditAssociation,
          activeTab,
          partModalOpen: viewEditAssociation && activeTab === "1",
          materialModalOpen: viewEditAssociation && activeTab === "2",
        });
        return null;
      })()}

      {/* Association Modals */}
      {/* Part Association Modal */}
      {viewEditAssociation && activeTab === "1" && (
        <ViewEditPartAssociationModal
          open={true}
          onCancel={() => {
            setViewEditAssociation(false);
          }}
          onSubmit={(parts) => {
            onCreatePartAssociation(parts);
            setOpenPartAssociationModal(false);
          }}
          itemVersionData={selectedItem}
          itemsData={collection}
          activeTab={activeTab}
        />
      )}

      {/* Material Association Modal */}
      {viewEditAssociation && activeTab === "2" && (
        <ViewEditMaterialAssociationModal
          open={true}
          onCancel={() => {
            setViewEditAssociation(false);
          }}
          onSubmit={(materials: Required<MeshMaterialModel>[]) => {
            onCreateMeshMaterialAssociation(materials);
            setOpenMeshMaterialAssociationModal(false);
          }}
          itemVersionData={selectedItem}
          itemsData={collection}
          activeTab={activeTab}
        />
      )}

      {/* Debug output */}
      {(() => {
        console.log("Modal render state:", {
          viewEditAssociation,
          activeTab,
          partModalOpen: viewEditAssociation && activeTab === "1",
          materialModalOpen: viewEditAssociation && activeTab === "2",
        });
        return null;
      })()}

      <PartMaterialAssociation
        open={openPartMeshMaterialAssociationModal}
        onCancel={() => {
          setSelectedPartsData([]);
          setOpenPartMeshMaterialAssociationModal(false);
        }}
        onSubmit={() => {
          setOpenPartMeshMaterialAssociationModal(false);
          // onCreatePartMaterialAssociation(parts);
        }}
        itemVersionData={selectedItem}
        setSelectedPartsData={setSelectedPartsData}
        selectedPartsData={selectedPartsData}
      />

      <CreateMeshMaterialAssociationModal
        open={openMeshMaterialAssociationModal}
        onCancel={() => {
          setOpenMeshMaterialAssociationModal(false);
        }}
        onSubmit={(meshMaterials) => {
          //onCreateMeshMaterialAssociationGroup(meshMaterials);
          onCreateMeshMaterialAssociation(meshMaterials);
          setOpenMeshMaterialAssociationModal(false);
        }}
        itemVersionData={selectedItem}
      />
      {modalContextHolder}
    </Layout>
  );
};

export const AdvancedProductCreator = ({ ...rest }) => {
  const { companyId, orderId, itemCollectionId } = useParams();
  return companyId && orderId ? (
    <CollectionContextProvider>
      <UIContextProvider>
        <AdvancedProductCreatorCore
          companyId={Number(companyId)}
          orderId={Number(orderId)}
          itemCollectionId={Number(itemCollectionId)}
          {...rest}
        />
      </UIContextProvider>
    </CollectionContextProvider>
  ) : (
    <div className="advanced-product-creator-wrapper-empty">
      <h2>Wrong url</h2>
    </div>
  );
};
