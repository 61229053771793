import React, { useCallback } from "react";
import { notification } from "antd";
import { useUIContext } from "../contexts/UIContext";
import { useCompanyContext } from "contexts/CompanyContext";
import {
  AtLeast,
  MeshMaterialModelType,
  PartModelType,
  SelectedItem,
} from "../types";
import { useCollectionContext } from "../contexts/CollectionContext";
import {
  MaterialModel,
  MeshMaterialModel,
  ProductsService,
  MeshMaterialAssociationModel,
} from "servicesNew/api";
import { eachParallel } from "utils";
import { getMeshMaterialModel } from "../utils/dataModel";
import _ from "lodash";

export const useMeshMaterials = () => {
  const { company } = useCompanyContext();
  const { taskWrapper } = useUIContext();
  const { updateItemByUUID } = useCollectionContext();

  const addMeshMaterials = useCallback(
    async ({
      //part,
      //material,
      //originPart,
      destinationPart,
      meshMaterials,
      selectedItem,
    }: {
      //part: PartModelType;
      //material: Required<MaterialModel>[];
      //originPart: PartModelType;
      destinationPart: PartModelType;
      meshMaterials: MeshMaterialModelType[];
      selectedItem: SelectedItem;
    }) => {
      /*************** Check for dublicates by name *****************/

      const diffMaterials = _.differenceWith(
        meshMaterials,
        destinationPart.childrens,
        (valueA, valueB) => valueA.name === valueB.name
      );

      const duplicatesCount = meshMaterials.length - diffMaterials.length;
      if (!diffMaterials.length) {
        notification.warning({
          message: "All selected materials already exists!",
        });
        return;
      }
      const materialIds = diffMaterials.map(
        (_meshMaterial) => _meshMaterial.id
      );
      /**************************************************************/
      const existingMeshMaterials =
        (selectedItem.item as PartModelType).childrens || [];
      const addedMeshMaterials = await Promise.all(
        diffMaterials.map(async (_meshMaterial, index) => {
          const price_mod = 0;
          //const materialPriceModOverrideDefault = 0;
          const response: MeshMaterialModelType[] =
            await ProductsService.productsMeshMaterialsCopy({
              data: {
                destination_part_id: destinationPart.id,
                company_id: _meshMaterial.company_id,
                origin_material_ids: [_meshMaterial.id],
                categories: _meshMaterial.categories,
              },
            });
          // Extract the id from the response
          console.log("response from productsmeshmaterialscopy:", response);
          const newMeshMaterialId = response[0].id;
          // Use the newMeshMaterialId in your PATCH request to productsMeshMaterialsUpdate
          const list_order = existingMeshMaterials.length + index;
          const updatedMeshMaterial =
            await ProductsService.productsMeshMaterialsUpdate({
              id: newMeshMaterialId,
              data: {
                list_order,
              },
            });
          return getMeshMaterialModel(
            updatedMeshMaterial,
            price_mod
            //materialPriceModOverrideDefault
          );
        })
      );

      updateItemByUUID(destinationPart._uuid, (_part) => {
        (_part as PartModelType).childrens.push(...addedMeshMaterials);
        return _part;
      });
      notification.success({
        message: "Materials successfully added!",
        description: duplicatesCount
          ? `Skipped ${duplicatesCount} ${
              duplicatesCount > 1 ? `duplicates` : "duplicate"
            }!`
          : undefined,
      });
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    },
    [updateItemByUUID]
  );

  /*const addMeshMaterials = useCallback(
    async ({
      //part,
      //material,
      //originPart,
      destinationPart,
      meshMaterials,
      selectedItem,
    }: {
      //part: PartModelType;
      //material: Required<MaterialModel>[];
      //originPart: PartModelType;
      destinationPart: PartModelType;
      meshMaterials: MeshMaterialModelType[];
      selectedItem: SelectedItem;
    }) => {
      const existingMeshMaterials =
        (selectedItem.item as PartModelType).childrens || [];
      const addedMeshMaterials = await Promise.all(
        meshMaterials.map(async (_meshMaterial, index) => {
          const price_mod = 0;
          const response: MeshMaterialModelType[] =
            await ProductsService.productsMeshMaterialsCopy({
              data: {
                destination_part_id: destinationPart.id,
                company_id: _meshMaterial.company_id,
                origin_material_ids: _meshMaterial.origin_material_ids,
              },
            });
          // Extract the id from the response
          console.log("response from productsmeshmaterialscopy:", response);
          const newMeshMaterialId = response[0].id;
          // Use the newMeshMaterialId in your PATCH request to productsMeshMaterialsUpdate
          const list_order = existingMeshMaterials.length + index;
          const updatedMeshMaterial =
            await ProductsService.productsMeshMaterialsUpdate({
              id: newMeshMaterialId,
              data: {
                list_order,
              },
            });
          return getMeshMaterialModel(updatedMeshMaterial, price_mod);
        })
      );

      updateItemByUUID(destinationPart._uuid, (_part) => {
        (_part as PartModelType).childrens.push(...addedMeshMaterials);
        return _part;
      });
      notification.success({
        message: "Mesh Materials successfully created!",
      });
    },
    [updateItemByUUID]
  );*/

  const updateMeshMaterial = useCallback(
    async (
      part: PartModelType,
      meshMaterial: AtLeast<
        MeshMaterialModelType,
        "_uuid" | "id" | "name" | "price_mod" | "visible"
      >
    ) => {
      if (
        typeof meshMaterial.id === "number" &&
        typeof meshMaterial._uuid === "string"
      ) {
        await ProductsService.productsPartMaterialModifierUpdate({
          partId: part.id,
          materialId: meshMaterial.id,
          data: {
            name: meshMaterial.name,
            price_mod: meshMaterial.price_mod,
          },
        });
        await ProductsService.productsMeshMaterialsUpdate({
          id: meshMaterial.id,
          data: {
            visible: meshMaterial.visible,
            custom_request_message: meshMaterial.custom_request_message,
            sku: meshMaterial.sku,
            display_sku: meshMaterial.display_sku,
          },
        });

        updateItemByUUID(meshMaterial._uuid, () => ({
          ...meshMaterial,
          //..._meshMaterial,
          name: meshMaterial.name,
          price_mod: meshMaterial.price_mod,
          visible: meshMaterial.visible,
        }));
        notification.success({
          message: "Material successfully updated!",
        });
      } else {
        console.error("Missing Material id or _uuid");
      }
    },
    [updateItemByUUID]
  );

  const removeMeshMaterial = useCallback(
    async (meshMaterial: MeshMaterialModelType, part: any) => {
      // Extracting the id of the association
      const extractIdsFromMaterialAssociations = (data: any) => {
        if (
          data.material_associations &&
          Array.isArray(data.material_associations)
        ) {
          return data.material_associations.map(
            (association: any) => association.id
          );
        }
        return [];
      };

      // Logic for material_category_association delete
      if (
        meshMaterial.material_category_associations &&
        meshMaterial.material_category_associations.length > 0
      ) {
        console.log("i am hit");
        await ProductsService.productsMaterialCategoryAssociationsDelete({
          categoryId: meshMaterial.material_category_associations[0].id,
          materialId: meshMaterial.id,
        });
      }

      // Logic for material_modifier delete
      if (meshMaterial.price_mod > 0) {
        await ProductsService.productsPartMaterialModifierDelete({
          partId: part.id,
          materialId: meshMaterial.id,
        });
      }
      // Logic for material_associations delete
      if (
        meshMaterial.material_associations &&
        meshMaterial.material_associations.length > 0
      ) {
        const ids = extractIdsFromMaterialAssociations(meshMaterial);

        for (const id of ids) {
          await ProductsService.productsMeshMaterialAssociationDelete({
            material_id: meshMaterial.id,
            material_association_group_id: id,
          });
        }
      }
      await ProductsService.productsMeshMaterialsDelete({
        id: meshMaterial.id,
      });
      updateItemByUUID(meshMaterial._uuid, (_meshMaterial, _part) => {
        (_part as PartModelType).childrens = (
          _part as PartModelType
        ).childrens.filter(({ _uuid }) => _uuid !== meshMaterial._uuid);
        return {};
      });
      notification.success({
        message: "Mesh Material successfully removed!",
      });
    },
    [updateItemByUUID]
  );

  const cloneMeshMaterials = useCallback(
    async ({
      destinationPart,
      meshMaterials,
      selectedItem,
    }: {
      destinationPart: PartModelType;
      meshMaterials: MeshMaterialModelType[];
      selectedItem: SelectedItem;
    }) => {
      /*************** Check for dublicates by name *****************/
      const diffMaterials = _.differenceWith(
        meshMaterials,
        destinationPart.childrens,
        (valueA, valueB) => valueA.name === valueB.name
      );
      const duplicatesCount = meshMaterials.length - diffMaterials.length;
      if (!diffMaterials.length) {
        notification.warning({
          message: "All selected materials already exists!",
        });
        return;
      }
      const materialIds = diffMaterials.map(
        (_meshMaterial) => _meshMaterial.id
      );
      /**************************************************************/
      const destinationCopy =
        (destinationPart as PartModelType).childrens || [];
      const clonedMeshMaterials = await Promise.all(
        diffMaterials.map(async (_meshMaterial, index) => {
          const price_mod = 0;
          //const materialPriceModOverrideDefault = 0;
          const response: MeshMaterialModelType[] =
            await ProductsService.productsMeshMaterialsCopy({
              data: {
                destination_part_id: destinationPart.id,
                //company_id: _meshMaterial.company_id,
                company_id: company?.id,
                origin_material_ids: [_meshMaterial.id],
                categories: _meshMaterial.material_category_associations,
              },
            });
          // Extract the id from the response

          const clonedMeshMaterialId = response[0].id;
          // Use the newMeshMaterialId in your PATCH request to productsMeshMaterialsUpdate
          const list_order = destinationCopy.length + index;
          const clonedMeshMaterial =
            await ProductsService.productsMeshMaterialsUpdate({
              id: clonedMeshMaterialId,
              data: {
                list_order,
              },
            });
          return getMeshMaterialModel(
            clonedMeshMaterial,
            price_mod
            //materialPriceModOverrideDefault
          );
        })
      );

      updateItemByUUID(destinationPart._uuid, (_part) => {
        (_part as PartModelType).childrens.push(...clonedMeshMaterials);
        return _part;
      });
      notification.success({
        message: "Materials successfully cloned!",
        description: duplicatesCount
          ? `Skipped ${duplicatesCount} ${
              duplicatesCount > 1 ? `duplicates` : "duplicate"
            }!`
          : undefined,
      });
    },
    [company?.id, updateItemByUUID]
  );

  /*const cloneMeshMaterials = useCallback(
    async ({
      destinationPart,
      meshMaterials,
      selectedItem,
    }: {
      destinationPart: PartModelType;
      meshMaterials: MeshMaterialModelType[];
      selectedItem: SelectedItem;
    }) => {
      const destinationCopy =
        (destinationPart as PartModelType).childrens || [];
      const clonedMeshMaterials = await Promise.all(
        meshMaterials.map(async (_meshMaterial, index) => {
          const price_mod = 0;
          const response: MeshMaterialModelType[] =
            await ProductsService.productsMeshMaterialsCopy({
              data: {
                destination_part_id: destinationPart.id,
                //company_id: _meshMaterial.company_id,
                company_id: company?.id,
                origin_material_ids: _meshMaterial.origin_material_ids,
              },
            });
          // Extract the id from the response
          console.log("response from productsmeshmaterialscopy:", response);
          const clonedMeshMaterialId = response[0].id;
          // Use the newMeshMaterialId in your PATCH request to productsMeshMaterialsUpdate
          const list_order = destinationCopy.length + index;
          const clonedMeshMaterial =
            await ProductsService.productsMeshMaterialsUpdate({
              id: clonedMeshMaterialId,
              data: {
                list_order,
              },
            });
          return getMeshMaterialModel(clonedMeshMaterial, price_mod);
        })
      );

      updateItemByUUID(destinationPart._uuid, (_part) => {
        (_part as PartModelType).childrens.push(...clonedMeshMaterials);
        return _part;
      });
      notification.success({
        message: "Mesh Materials successfully created!",
      });
    },
    [updateItemByUUID]
  );*/

  const createMeshMaterialAssociationGroup = useCallback(
    async (
      meshMaterial: AtLeast<
        MeshMaterialModelType,
        "ui_message" | "type" | "item_version_id"
      >
    ) => {
      if (
        typeof meshMaterial.id === "number" &&
        typeof meshMaterial._uuid === "string"
      ) {
        const response =
          await ProductsService.productsMeshMaterialAssociationGroupCreate({
            data: {
              ui_message: meshMaterial.ui_message,
              type: meshMaterial.type,
              item_version_id: meshMaterial.item_version_id,
            },
          });
        updateItemByUUID(meshMaterial._uuid, () => ({
          ...meshMaterial,
          ui_message: meshMaterial.ui_message,
          type: meshMaterial.type,
        }));
        notification.success({
          message: "Material Association Group successfully created!",
        });
        return response;
      } else {
        console.error("Invalid Material Association Group");
      }
    },
    [updateItemByUUID]
  );

  /*
  const createMeshMaterialAssociationGroup = useCallback(
    async (
      meshMaterial: AtLeast<
        MeshMaterialAssociationModel,
        "ui_message" | "type"
        //"_uuid" | "id" | "ui_message" | "type"
      >
    ) => {
      if (
        typeof meshMaterial.id === "number" &&
        typeof meshMaterial._uuid === "string"
      ) {
        console.log(
          "Creating Material Association Group with UI message:",
          meshMaterial.ui_message,
          "and type:",
          meshMaterial.type
        ); // Added console log
        await ProductsService.productsMeshMaterialAssociationGroupCreate({
          //materialId: meshMaterial.id,
          data: {
            ui_message: meshMaterial.ui_message,
            type: meshMaterial.type,
          },
        });

        updateItemByUUID(meshMaterial._uuid, () => ({
          ...meshMaterial,
          ui_message: meshMaterial.ui_message,
          type: meshMaterial.type,
        }));
        notification.success({
          message: "Material Association Group successfully created!",
        });
      } else {
        console.error("Invalid Material Association Group");
      }
    },
    [updateItemByUUID]
  );
  */

  const createMeshMaterialAssociation = useCallback(
    async (
      //materialData: MeshMaterialModelType,
      meshMaterial: AtLeast<
        MeshMaterialModelType,
        /*"_uuid" | "id" | "ui_message" | "type" | "material_id"
        |*/ "material_association_group_id"
      >
    ) => {
      if (
        typeof meshMaterial.id === "number" &&
        typeof meshMaterial._uuid === "string"
      ) {
        console.log("materialId:", meshMaterial.id);
        console.log("materialInCreate:", meshMaterial);
        console.log(
          "materialAssociationGroupId:",
          meshMaterial.material_association_group_id
        );
        const price_mod = 0;
        //const materialPriceModOverrideDefault = 0;
        const assocResponse =
          await ProductsService.productsMeshMaterialAssociationCreate({
            materialId: meshMaterial.id,
            materialAssociationGroupId:
              meshMaterial.material_association_group_id,
            data: {
              //ui_message: meshMaterial.ui_message,
              //type: meshMaterial.type,
            },
          });

        updateItemByUUID(meshMaterial._uuid, () => ({
          ...meshMaterial,
          materialAssociationGroupId:
            meshMaterial.material_association_group_id,
          materialId: meshMaterial.id,
          ui_message: meshMaterial.ui_message,
          type: meshMaterial.type,
        }));
        notification.success({
          message: "Material Association successfully created!",
        });
        return getMeshMaterialModel(
          meshMaterial,
          price_mod
          //materialPriceModOverrideDefault
        );
        //return assocResponse;
      } else {
        console.error("Invalid Material Association");
      }
    },
    [updateItemByUUID]
  );

  /*const createMeshMaterialAssociation = useCallback(
    async ({
      meshMaterialAssociation,
      name,
    }: {
      meshMaterialAssociation: MeshMaterialModelType;
      name: string;
    }) => {
      const MeshMaterialModel = await createMeshMaterialAssociation({
        meshMaterialAssociationId: meshMaterialAssociation.id,
        meshMaterialAssociationName: meshMaterialAssociation.name || "Standard Base",
        name,
      });
      updateItemByUUID(meshMaterial._uuid, (_meshMaterial) => {
        (_meshMaterial as MeshMaterialModelType).childrens.push(meshMaterialModel);
        return _meshMaterial;
      });
      notification.success({
        message: "MeshMaterial association successfully created!",
      });
    },
    [updateItemByUUID]
  );

  const modifyMeshMaterialAssociation = useCallback(
    async (
      meshMaterialAssociation: AtLeast<MeshMaterialAssociationModelType, "name" | "price_mod">
    ) => {
      if (typeof meshMaterialAssociation.id === "number" && typeof meshMaterialAssociation._uuid === "string") {
        await ProductsService.productsMeshMaterialAssociationsUpdate({
          id: meshMaterialAssociation.id,
          data: { name: meshMaterialAssociation.name, price_mod: meshMaterialAssociation.price_mod },
        });

        updateItemByUUID(meshMaterialAssociation._uuid, () => ({
          ...meshMaterialAssociation,
          name: meshMaterialAssociation.name,
          price_mod: meshMaterialAssociation.price_mod,
        }));
        notification.success({
          message: "MeshMaterialAssociation successfully updated!",
        });
      } else {
        console.error("Missing MeshMaterialAssociation id or _uuid");
      }
    },
    [updateItemByUUID]
  );

  const deleteMeshMaterialAssociation = useCallback(
    async ({ meshMaterialAssociation }: { meshMaterialAssociation: MeshMaterialAssociationModelType }) => {
      await ProductsService.productsMeshMaterialAssociationsDelete({
        id: meshMaterialAssociation.id,
      });
      updateItemByUUID(meshMaterialAssociation._uuid, (_meshMaterialAssociation, _meshMaterialAssociation) => {
        (_meshMaterialAssociation as MeshMaterialAssociationModelType).childrens = (
          _meshMaterialAssociation as MeshMaterialAssociationModelType
        ).childrens.filter(({ _uuid }) => _uuid !== meshMaterialAssociation._uuid);
        return {};
      });
      notification.success({
        message: "MeshMaterialAssociation successfully removed!",
      });
    },
    [updateItemByUUID]
  );*/

  const deleteMeshMaterialAssociation = useCallback(
    async (materialId: number, materialAssociationGroupId: number) => {
      try {
        console.log("Starting deleteMeshMaterialAssociation with:", {
          materialId,
          materialAssociationGroupId,
        });

        // Make sure we have valid IDs
        if (!materialId || !materialAssociationGroupId) {
          const error = new Error(
            "Invalid material ID or association group ID"
          );
          console.error("Validation failed:", error);
          throw error;
        }

        console.log(
          "Making DELETE request to ProductsService.productsMeshMaterialAssociationDelete"
        );
        const result =
          await ProductsService.productsMeshMaterialAssociationDelete({
            material_id: materialId,
            material_association_group_id: materialAssociationGroupId,
          });

        console.log("DELETE request completed successfully:", {
          materialId,
          materialAssociationGroupId,
          result,
        });

        notification.success({
          message: "Material Association successfully deleted!",
          description: `Removed material ${materialId} from association ${materialAssociationGroupId}`,
        });

        return result;
      } catch (error) {
        const errorMessage =
          error instanceof Error ? error.message : "Unknown error";
        console.error("Failed to delete material association:", {
          materialId,
          materialAssociationGroupId,
          error: errorMessage,
          stack: error instanceof Error ? error.stack : undefined,
        });

        notification.error({
          message: "Failed to delete material association",
          description: errorMessage,
        });
        throw error;
      }
    },
    []
  );

  const patchMeshMaterialAssociation = useCallback(
    async (id: number, type: string, ui_message: string) => {
      try {
        console.log("Starting patchMeshMaterialAssociation with:", {
          id,
          type,
          ui_message,
        });

        const result =
          await ProductsService.productsMeshMaterialAssociationGroupModify({
            materialAssociationGroupId: id,
            data: {
              type,
              ui_message,
            },
          });

        console.log("PATCH request completed successfully:", result);

        notification.success({
          message: "Material Association successfully updated!",
        });

        return result;
      } catch (error) {
        const errorMessage =
          error instanceof Error ? error.message : "Unknown error";
        console.error("Failed to update material association:", {
          id,
          type,
          ui_message,
          error: errorMessage,
          stack: error instanceof Error ? error.stack : undefined,
        });

        notification.error({
          message: "Failed to update material association",
          description: errorMessage,
        });
        throw error;
      }
    },
    []
  );

  const deleteMeshMaterialAssociationGroup = useCallback(
    async (associationGroupId: number, selectedMaterials: any[]) => {
      try {
        console.log("Starting deleteMeshMaterialAssociationGroup with:", {
          associationGroupId,
          selectedMaterials,
        });

        // First delete all material associations
        for (const material of selectedMaterials) {
          await deleteMeshMaterialAssociation(material.id, associationGroupId);
        }

        // Then delete the association group itself
        await ProductsService.productsMeshMaterialAssociationGroupDelete({
          material_association_group_id: associationGroupId,
        });

        notification.success({
          message: "Material association group successfully deleted!",
        });
      } catch (error) {
        const errorMessage =
          error instanceof Error ? error.message : "Unknown error";
        console.error("Failed to delete material association group:", {
          associationGroupId,
          error: errorMessage,
          stack: error instanceof Error ? error.stack : undefined,
        });

        notification.error({
          message: "Failed to delete material association group",
          description: errorMessage,
        });
        throw error;
      }
    },
    [deleteMeshMaterialAssociation]
  );

  return {
    updateMeshMaterial: taskWrapper(updateMeshMaterial),
    addMeshMaterials: taskWrapper(addMeshMaterials),
    removeMeshMaterial: taskWrapper(removeMeshMaterial),
    cloneMeshMaterials: taskWrapper(cloneMeshMaterials),
    createMeshMaterialAssociationGroup: taskWrapper(
      createMeshMaterialAssociationGroup
    ),
    createMeshMaterialAssociation: taskWrapper(createMeshMaterialAssociation),
    deleteMeshMaterialAssociation: taskWrapper(deleteMeshMaterialAssociation),
    deleteMeshMaterialAssociationGroup: taskWrapper(
      deleteMeshMaterialAssociationGroup
    ),
    patchMeshMaterialAssociation: taskWrapper(patchMeshMaterialAssociation),
  };
};
