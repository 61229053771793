/* eslint-disable prettier/prettier */
import React, { useState, useEffect } from "react";
import { Tabs, Tree, Collapse, Button, Row, Col } from "antd";
import { DownOutlined, PlusOutlined } from "@ant-design/icons";
import PartAssociationsView from "./partAssociationsView";
import MaterialAssociationsView from "./materialAssociationsView";

const { Panel } = Collapse;

interface TreeNode {
  title: string;
  key: string;
  children?: TreeNode[];
  isLeaf?: boolean;
  associationId?: number;
  materialId?: number;
}

interface AssociationModuleMasterProps {
  selectedID: number | null;
  itemsData: any;
  initialSelectedParts?: { id: number; name: string; childrens: any }[];
  onPartSelect?: (partId: number, isSelected: boolean) => void;
  onMaterialSelect?: (materialId: number, isSelected: boolean) => void;
  onUiMessageChange?: (message: string) => void;
  onAssociationTypeChange?: (type: string) => void;
  onDeleteClick?: () => void;
  onDeleteMaterialAssociationClick?: () => void;
  activeTab?: string;
  onTabChange?: (tab: string) => void;
  onAssociationSelect?: (associationId: number | null, tabType: "1" | "2") => void;
}

const AssociationModuleMaster: React.FC<AssociationModuleMasterProps> = ({
  itemsData,
  initialSelectedParts,
  onPartSelect,
  onMaterialSelect,
  onUiMessageChange,
  onAssociationTypeChange,
  onDeleteClick,
  onDeleteMaterialAssociationClick,
  activeTab,
  onTabChange,
  onAssociationSelect,
}) => {
  const [selectedID, setSelectedID] = useState<number | null>(null);
  const [internalActiveTab, setInternalActiveTab] = useState(activeTab || "1");

  // Keep internal state in sync with prop
  useEffect(() => {
    if (activeTab) {
      setInternalActiveTab(activeTab);
    }
  }, [activeTab]);

  const handleTabChange = (tab: string) => {
    console.log('Tab change started:', { 
      from: internalActiveTab, 
      to: tab,
      hasOnTabChange: !!onTabChange,
      hasOnAssociationSelect: !!onAssociationSelect
    });
    
    // First notify parent about tab change
    if (onTabChange) {
      console.log('Calling onTabChange with:', tab);
      onTabChange(tab);
    }
    
    // Then notify about association deselection
    if (onAssociationSelect) {
      console.log('Calling onAssociationSelect with:', { associationId: null, tabType: tab });
      onAssociationSelect(null, tab as "1" | "2");
    }
    
    // Finally update internal state
    setInternalActiveTab(tab);
    setSelectedID(null);
    setTreeData([]);
  };

  const renderContent = () => {
    if (internalActiveTab === "1") {
      return (
        <PartAssociationsView
          selectedID={selectedID}
          itemsData={itemsData}
          selectedItemId={selectedItemId}
          initialSelectedParts={initialSelectedParts}
          onPartSelect={onPartSelect}
          onUiMessageChange={onUiMessageChange}
          onAssociationTypeChange={onAssociationTypeChange}
          onDeleteClick={onDeleteClick}
          activeTab={internalActiveTab}
        />
      );
    } else {
      return (
        <MaterialAssociationsView
          selectedID={selectedID}
          itemsData={itemsData}
          selectedItemId={selectedItemId}
          onMaterialSelect={onMaterialSelect}
          onUiMessageChange={onUiMessageChange}
          onAssociationTypeChange={onAssociationTypeChange}
          onDeleteMaterialAssociationClick={onDeleteMaterialAssociationClick}
          activeTab={internalActiveTab}
        />
      );
    }
  };

  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const [activeItem, setActiveItem] = useState(0);
  const [treeData, setTreeData] = useState<TreeNode[]>([]);
  const [selectedItemId, setSelectedItemId] = useState<number | null>(null);
  const [itemNames, setItemNames] = useState<{ name: string; id: number; list_order: number }[]>([]);

  // Extract item names and set initial selectedItemId
  useEffect(() => {
    console.log("Initializing component with:", {
      itemsData,
      activeTab: internalActiveTab,
      selectedItemId
    });

    if (Array.isArray(itemsData?.items)) {
      const extractedData = itemsData.items
        .map((item: any) => {
          const { name, id, list_order } = item as { name?: string; id?: any; list_order?: number };
          return { name, id, list_order: list_order || 0 }; // Default to 0 if list_order is undefined
        })
        .filter(
          (item: any): item is { name: string; id: number; list_order: number } =>
            item.name !== undefined && item.id !== undefined
        )
        .sort((a: { list_order: number }, b: { list_order: number }) => a.list_order - b.list_order);

      console.log("Extracted item names:", extractedData);
      setItemNames(extractedData);
      
      // Set initial selectedItemId to first item when component mounts
      if (extractedData.length > 0 && !selectedItemId) {
        console.log("Setting initial selectedItemId:", {
          id: extractedData[0].id,
          name: extractedData[0].name
        });
        setSelectedItemId(extractedData[0].id);
        setActiveItem(0); // Also set active item index
      }
    } else {
      console.log("No items found in itemsData");
      setItemNames([]);
    }
  }, [itemsData, internalActiveTab]);

  const handleCollapseChange = () => {
    setIsPanelOpen(!isPanelOpen);
  };

  const buildTreeData = (id: any, children: any[], currentTab: string) => {
    console.log("Building tree data for tab:", {
      currentTab,
      id,
      childrenCount: children?.length
    });

    const childOne = children.find((child: any) => child.id === id);
    if (!childOne) {
      console.log("No childOne found for id:", id);
      return [];
    }

    console.log("Found childOne:", {
      id: childOne.id,
      name: childOne.name,
      childrenCount: childOne.childrens?.length,
      hasChildrens: Boolean(childOne.childrens?.length)
    });

    const groups = new Map();

    childOne.childrens.forEach((childTwo: any) => {
      childTwo.childrens.forEach((childThree: any) => {
        childThree.childrens.forEach((childFour: any) => {
          // Handle Parts
          if (currentTab === "1" && childFour.part_association_groups?.length > 0) {
            console.log("Processing part associations for childFour:", {
              childFourName: childFour.name,
              hasPartAssociations: childFour.part_association_groups?.length > 0,
              partAssociationCount: childFour.part_association_groups?.length,
              partAssociations: childFour.part_association_groups
            });

            const partGroupName = childThree.name || 'Unknown Group';
            
            if (!groups.has(childThree.id)) {
              groups.set(childThree.id, {
                title: partGroupName,
                key: `pg-${childOne.id}-${childThree.id}`,
                children: [],
              });
            }

            const partNode = {
              title: childFour.name,
              key: `p-${childOne.id}-${childFour.id}`,
              children: childFour.part_association_groups.map((group: any) => ({
                title: `Association ${group.id}`,
                key: `assoc-${childFour.id}-${group.id}`,
                isLeaf: true,
                associationId: group.id,
              })),
            };
            groups.get(childThree.id).children.push(partNode);
          }
          
          // Handle Materials
          if (currentTab === "2" && childFour.childrens?.length > 0) {
            console.log("Processing materials for childFour:", {
              childFourName: childFour.name,
              hasChildrens: childFour.childrens?.length > 0,
              childrenCount: childFour.childrens?.length,
              childrens: childFour.childrens,
              rawChildFour: childFour
            });

            childFour.childrens.forEach((childFive: any) => {
              console.log("Processing childFive for materials:", {
                childFiveName: childFive.name,
                hasMaterialAssociations: childFive.material_associations?.length > 0,
                materialAssociationCount: childFive.material_associations?.length,
                materialAssociations: childFive.material_associations,
                rawChildFive: childFive
              });

              // Check if this material has any associations
              const materialAssociations = childFive.material_associations || [];
              console.log("Checking material associations:", {
                materialId: childFive.id,
                materialName: childFive.name,
                associations: materialAssociations,
                selectedID
              });

              // Only process materials that have associations
              if (materialAssociations.length > 0) {
                const materialGroupName = childThree.name || 'Unknown Group';
                
                // Create group node if it doesn't exist
                if (!groups.has(childThree.id)) {
                  groups.set(childThree.id, {
                    title: materialGroupName,
                    key: `mg-${childOne.id}-${childThree.id}`,
                    children: [],
                  });
                }

                // Create part node if it doesn't exist
                const partNodeKey = `p-${childOne.id}-${childFour.id}`;
                let partNode = groups.get(childThree.id).children.find(
                  (node: TreeNode) => node.key === partNodeKey
                );

                if (!partNode) {
                  partNode = {
                    title: childFour.name,
                    key: partNodeKey,
                    children: [],
                  };
                  groups.get(childThree.id).children.push(partNode);
                }

                // Create material node with its associations
                console.log('Creating material node for:', {
                  materialName: childFive.name,
                  materialId: childFive.id,
                  associationsCount: materialAssociations.length,
                  partNodeKey,
                  partNodeChildren: partNode.children?.length
                });

                const materialNode = {
                  title: childFive.name,
                  key: `m-${childOne.id}-${childFive.id}`,
                  children: materialAssociations.map((assoc: any) => {
                    const associationNode = {
                      title: `Association ${assoc.id}`,
                      key: `assoc-${childFive.id}-${assoc.id}`,
                      isLeaf: true,
                      associationId: Number(assoc.id), // Ensure it's a number
                      materialId: Number(childFive.id), // Ensure it's a number
                      type: assoc.type,
                      ui_message: assoc.ui_message
                    };
                    console.log("Creating association node:", {
                      node: associationNode,
                      associationIdType: typeof associationNode.associationId
                    });
                    return associationNode;
                  }).filter((node: TreeNode) => {
                    // If we have a selectedID, only show that association
                    if (selectedID !== null) {
                      return node.associationId === selectedID;
                    }
                    return true;
                  }),
                };

                // Only add the node if it has associations (and if filtering by selectedID, only if it has matching associations)
                if (materialNode.children.length > 0) {
                  console.log("Adding material node with filtered associations:", {
                    materialName: childFive.name,
                    associations: materialNode.children,
                    selectedID
                  });
                  partNode.children.push(materialNode);
                }
              }
            });
          }
        });
      });
    });

    const result = Array.from(groups.values());
    
    // Deep inspect the tree data
    const inspectNode = (node: TreeNode, depth = 0) => {
      console.log('Tree node at depth', depth, ':', {
        title: node.title,
        key: node.key,
        associationId: node.associationId,
        materialId: node.materialId,
        isLeaf: node.isLeaf,
        childrenCount: node.children?.length
      });
      
      if (node.children) {
        node.children.forEach(child => inspectNode(child, depth + 1));
      }
    };
    
    console.log('Built tree data:', {
      currentTab,
      groupsCount: groups.size
    });
    
    console.log('Inspecting full tree structure:');
    result.forEach(node => inspectNode(node));
    
    return result;
  };

  // Update tree data when tab changes or selectedItemId changes
  // Initialize tree data when component mounts or when itemsData changes
  useEffect(() => {
    console.log("AssociationModuleMaster useEffect:", {
      selectedItemId,
      itemsData,
      internalActiveTab,
      itemNames
    });

    if (selectedItemId && itemsData?.childrens) {
      const newTreeData = buildTreeData(selectedItemId, itemsData.childrens, internalActiveTab);
      console.log("Setting new tree data:", newTreeData);
      setTreeData(newTreeData);
    }
  }, [internalActiveTab, selectedItemId, itemsData, itemNames]);

  return (
    <div>
      <div className="selected-id">
        Association ID:{" "}
        <span className="selected-id-btn">
          {selectedID !== null ? selectedID : ""}
        </span>
      </div>
      <div className="part-materials-container">
        <Row gutter={16}>
          <Col span={8}>
            <div className="items-selection">
              <Collapse
                onChange={handleCollapseChange}
                expandIcon={({ isActive }) => (
                  <div className={`rotate-btn${isPanelOpen ? "-active" : ""}`}>
                    <DownOutlined
                      rotate={isActive ? 180 : 0}
                      className={`rotate-icon${isPanelOpen ? "-active" : ""}`}
                    />
                  </div>
                )}
                expandIconPosition="right"
                className="items-dropdown"
              >
                <Panel
                  header={itemNames?.[activeItem]?.name}
                  key="1"
                  className={`panel-header ${isPanelOpen ? "active" : ""}`}
                >
                  {itemNames?.map((item, index) => (
                    <p
                      key={index}
                      className="items-name"
                      onClick={() => {
                        setActiveItem(index);
                        setSelectedItemId(item.id);
                      }}
                    >
                      {item?.name}
                    </p>
                  ))}
                </Panel>
              </Collapse>
            </div>
            <div className="association-ids-section">
              <div className="association-ids-header">
                <div className="association-header-text">
                  Search Associations
                </div>
                <div className="association-btn">
                  <PlusOutlined className="association-icon" />
                </div>
              </div>
              <div className="tree-section">
                <Tree
                  showLine={{ showLeafIcon: false }}
                  showIcon={false}
                  defaultExpandAll
                  onLoad={(loadedKeys) => {
                    console.log('Tree nodes loaded:', {
                      loadedKeys,
                      currentTreeData: treeData
                    });
                  }}
                  onSelect={(selectedKeys) => {
                    if (selectedKeys.length === 0) {
                      // Handle deselection
                      console.log('Tree node deselected');
                      if (onAssociationSelect) {
                        onAssociationSelect(null, internalActiveTab as "1" | "2");
                      }
                      return;
                    }

                    const key = selectedKeys[0]?.toString() || "";
                    // Only set selectedID if it's a leaf node (association ID)
                    if (key.startsWith('assoc-')) {
                      console.log('Tree node selected:', key);
                      
                      // Find the node to get its associationId and materialId
                      const findAssociationNode = (nodes: TreeNode[]): TreeNode | undefined => {
                        const findInNode = (node: TreeNode): TreeNode | undefined => {
                          console.log('Searching node:', {
                            key: node.key,
                            matchingKey: key,
                            isMatch: node.key === key,
                            associationId: node.associationId,
                            materialId: node.materialId,
                            nodeType: node.key.split('-')[0], // e.g., 'assoc', 'm', 'p'
                            fullNode: node
                          });
                          
                          if (node.key === key) {
                            console.log('Found matching node:', {
                              key: node.key,
                              associationId: node.associationId,
                              associationIdType: typeof node.associationId,
                              materialId: node.materialId,
                              materialIdType: typeof node.materialId,
                              fullNode: node
                            });
                            return node;
                          }
                          
                          if (node.children) {
                            for (const child of node.children) {
                              const found = findInNode(child);
                              if (found) return found;
                            }
                          }
                          return undefined;
                        };

                        console.log('Starting node search in tree data:', {
                          searchKey: key,
                          topLevelNodes: nodes.map(n => ({
                            key: n.key,
                            title: n.title,
                            hasChildren: !!n.children?.length
                          }))
                        });

                        for (const node of nodes) {
                          const found = findInNode(node);
                          if (found) return found;
                        }
                        return undefined;
                      };

                      const node = findAssociationNode(treeData);
                      console.log('Found association node:', {
                        node,
                        key,
                        treeData,
                        internalActiveTab
                      });
                      
                      if (typeof node?.associationId !== 'number') {
                        console.log('No associationId found in node');
                        return;
                      }

                      console.log('Processing association:', {
                        associationId: node.associationId,
                        materialId: node.materialId,
                        tabType: internalActiveTab,
                        isMaterialTab: internalActiveTab === "2"
                      });

                      if (internalActiveTab === "2") {
                        // Material tab - need both associationId and materialId
                        if (typeof node.materialId !== 'number') {
                          console.error('Invalid material association node - missing materialId:', node);
                          return;
                        }

                        // First notify parent about material selection
                        if (onMaterialSelect) {
                          console.log('Selecting material:', {
                            materialId: node.materialId,
                            associationId: node.associationId,
                            isSelected: true
                          });
                          onMaterialSelect(node.materialId, true);
                        }

                        // Then update association
                        setSelectedID(node.associationId);
                        if (onAssociationSelect) {
                          onAssociationSelect(node.associationId, "2");
                        }
                      } else {
                        // Parts tab - only need associationId
                        setSelectedID(node.associationId);
                        if (onAssociationSelect) {
                          onAssociationSelect(node.associationId, "1");
                        }
                      }
                    }
                  }}
                  treeData={treeData}
                />
              </div>
            </div>
          </Col>
          <Col span={16}>
      <div className="tabs-container">
        <Tabs defaultActiveKey="1" activeKey={internalActiveTab} onChange={handleTabChange}>
          <Tabs.TabPane tab="Parts" key="1">
            {renderContent()}
          </Tabs.TabPane>
          <Tabs.TabPane tab="Materials" key="2">
            {renderContent()}
          </Tabs.TabPane>
        </Tabs>
      </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export { AssociationModuleMaster };
